import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import { AxiosError } from 'axios';
import { produce } from 'immer';
import moment from 'moment';
import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { NormalAlert } from '../../../components/core/alert-card/alert-cards';
import useCopyOrPasteShortcut from '../../../components/custom-hooks/useCopyOrPasteShortcut';
import LogoLoading from '../../../components/ui/logo-loading';
import useCommon from '../../../resources/common/common-hook';
import useMacroGraph from '../../../resources/macro-graph/macro-graph-hook';
import {
  EditTotalSelectedValue,
  IMacroGraphResponse,
  initialSelectedCells,
  Line,
} from '../../../resources/macro-graph/macro-graph-types';
import macroGraphService from '../../../resources/macro-graph/macro-graph.service';
import { parseNumberFromString } from '../../../utils/parse-number-from-string';
import './index.scss';
import {
  formatMonthNumber,
  formatRowIdToYearMonth,
  FormattedDataType,
  FormattedRow,
  getPredYrMo,
  headers,
  monthsColumns,
} from './utils';

// all in milliseconds
const MAX_TOTAL_ROWS_WITHOUT_LOADING = 25;
const INITIAL_LOADING_TIME_FOR_EVERY_10_ROWS = 565;
const SWITCH_COUNTRY_WAIT_TIME_FOR_EVERY_10_ROWS = 85;

const TABLE_MAX_HEIGHT = 350;
const MAX_TOTAL_ROWS_WITHOUT_MAX_HEIGHT = 50;

interface Props {
  data: IMacroGraphResponse;
  lines: Line[];
  rowNumber: number;
  setSelectedTotalSum: (selectedTotalSum: number) => void;
  browserByCorpCodeSelected: string;
}

const EditableYearMonthTable: React.FC<Props> = ({
  data,
  rowNumber,
  setSelectedTotalSum,
  lines,
  browserByCorpCodeSelected,
}) => {
  const [rawDataSource, setRawDataSource] = useState<Line[]>([]);
  const {
    tableRealTimeData,
    updateRealTimeTableData,
    chartStyle,
    setCombinedSelectedCells,
    combinedSelectedCells,
    chartDataIsDirty,
    setChartDataIsDirty,
    loadingRealTimeTableData,
    setLoadingRealTimeTableData,
  } = useMacroGraph();
  const [editedCells, setEditedCells] = useState<string[]>([]);
  const { dataTypesMetadata, fetchAllDataTypesMetadata } = useCommon();
  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const [loadingNewValues, setLoadingNewValues] = useState<boolean>(false);
  const [selectedCells, setSelectedCells] = useState<Record<string, boolean>>({});
  // this will be used to stop the re-rendering of unnecessary tables
  const [tableHasChanged, setTableHasChanged] = useState<boolean>(false);
  const lastClickedButtonRef = useRef<'enter' | 'tab' | undefined>('enter');

  const cellSelectionCount = useRef(0);

  useEffect(() => {
    if (dataTypesMetadata.length === 0) fetchAllDataTypesMetadata();
  }, []);

  useEffect(() => {
    if (!chartDataIsDirty) {
      setEditedCells([]);
    }
  }, [chartDataIsDirty]);

  useEffect(() => {
    if (JSON.stringify(combinedSelectedCells[rowNumber]) !== JSON.stringify(selectedCells)) {
      unstable_batchedUpdates(() => {
        setSelectedCells(combinedSelectedCells[rowNumber] ?? {});
        setTableHasChanged(true);
      });
    } else {
      setTableHasChanged(false);
    }
  }, [combinedSelectedCells[rowNumber]]);

  const isFiscal = useMemo(() => chartStyle.calendars === 'fiscal', [chartStyle.calendars]);
  const monthsArray = useMemo(() => (isFiscal ? monthsColumns.fiscal : monthsColumns.default), [isFiscal]);
  const memoizedSelectedCells = useMemo(() => selectedCells, [selectedCells]);

  const [realTimeDataRows, setRealTimeDataRows] = useState<Line[] | undefined>(undefined);
  useEffect(() => {
    const fetchRealTimeDataRows = async () => {
      // Async processing with a small delay using setTimeout to prevent blocking
      await new Promise(resolve => setTimeout(resolve, 0));

      if (tableRealTimeData !== undefined) {
        let response;

        if (chartStyle.graphOptions.browseByCorpCode && browserByCorpCodeSelected !== '') {
          response = tableRealTimeData.graphs
            .flatMap(graph => graph.lines.find(line => line.countryCodes[0] === browserByCorpCodeSelected))
            .filter((line): line is Line => line !== undefined);
        } else {
          response = tableRealTimeData.graphs.flatMap(graph => graph.lines);
        }

        setRealTimeDataRows(response);
      } else {
        setRealTimeDataRows(undefined);
      }
    };

    fetchRealTimeDataRows();
  }, [
    tableRealTimeData,
    browserByCorpCodeSelected,
    chartStyle.graphOptions.browseByCorpCode,
    setLoadingRealTimeTableData,
  ]);

  const memorizedBrowserByCorpCodeSelected = useMemo(() => browserByCorpCodeSelected, [browserByCorpCodeSelected]);

  const isComparison = useMemo(
    () => rawDataSource?.[rowNumber]?.title.includes('Comparison'),
    [rawDataSource, rowNumber],
  );
  const is12MonthRollingAvg = useMemo(
    () => chartStyle.lineConfigurations[rowNumber].is12MonthRollingAvg,
    [chartStyle.lineConfigurations, rowNumber],
  );

  useEffect(() => {
    setRawDataSource(lines);
    setEditedCells(lines?.[rowNumber]?.yearMonthDraft?.map(val => String(val)) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines, chartStyle.calendars]);

  const lockedCells = useMemo(() => {
    if (is12MonthRollingAvg || isComparison) {
      return rawDataSource?.[rowNumber]?.rows?.map(row => {
        return String(row.yearMonth);
      });
    }

    return (
      rawDataSource?.[rowNumber]?.rows?.flatMap(row => {
        return row.isLocked ? String(row.yearMonth) : [];
      }) ?? []
    );
  }, [is12MonthRollingAvg, isComparison, rawDataSource, rowNumber]);

  const unlockedCells = useMemo(() => {
    if (is12MonthRollingAvg || isComparison) {
      return rawDataSource?.[rowNumber]?.rows?.map(row => {
        return String(row.yearMonth);
      });
    }

    return (
      rawDataSource?.[rowNumber]?.rows?.flatMap(row => {
        return !row.isLocked ? String(row.yearMonth) : [];
      }) ?? []
    );
  }, [is12MonthRollingAvg, isComparison, rawDataSource, rowNumber]);

  const [tablesResult, setTablesResult] = useState<FormattedDataType | null>(null);

  const [fetchingTableStructure, setFetchingTableStructure] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      setFetchingTableStructure(true);
      const tablesResult = await macroGraphService.getGraphFormattedData(
        realTimeDataRows !== undefined ? realTimeDataRows : rawDataSource,
        rowNumber,
        chartStyle,
        isFiscal,
        dataTypesMetadata,
      );
      setTablesResult(tablesResult);
      setFetchingTableStructure(false);
    };
    if (rawDataSource && rawDataSource.length > 0) {
      fetchData();
    }
  }, [isFiscal, rawDataSource, realTimeDataRows]);

  useEffect(() => {
    return () => {
      setTablesResult(null);
      setRealTimeDataRows(undefined);
      setRawDataSource([]);
    };
  }, []);

  //* INITIAL DATA FORMATTING
  const formatedData = useMemo<FormattedDataType | null>(() => {
    if (isFiscal && tablesResult) {
      tablesResult.map(table =>
        table.rows.forEach((row, rowIndex) => {
          const currentYear = Number(row.year);
          const previousYear = currentYear - 1;
          row.year = `${previousYear % 100}/${currentYear % 100}`;
        }),
      );
    }

    return tablesResult;
  }, [isFiscal, tablesResult]);

  const lockUnlockValuesOnClick = useCallback(
    (lock: boolean, yearMonthsToBeEdited: string[]) => {
      const contryCode = chartStyle.graphOptions.browseByCorpCode
        ? memorizedBrowserByCorpCodeSelected
        : chartStyle.lineConfigurations[rowNumber].countryCodes[0];

      void macroGraphService.lockUnlockValues({
        countryCode: contryCode,
        dataType: chartStyle.lineConfigurations[rowNumber].dataType,
        yearMonths: yearMonthsToBeEdited.map(yearMonth => Number(yearMonth)),
        lock,
      });

      const newRows = rawDataSource?.[rowNumber].rows.map(row => {
        if (editedCells.includes(String(row.yearMonth))) {
          const year = row.yearMonth.toString().slice(0, 4);
          const month = row.yearMonth.toString().slice(4, 6);
          const monthStr = monthsArray[parseInt(month, 10) - 1];
          const yearRow = formatedData?.[rowNumber].rows.find(r => r.year === year);
          // @ts-ignore
          const value = Number(yearRow[monthStr]);
          const scaleResultsBy = chartStyle.graphOptions.scaleResults ? 1000 : 1;
          row.value = value * scaleResultsBy;
        }
        if (yearMonthsToBeEdited.includes(String(row.yearMonth))) {
          row.isLocked = lock;
        }
        return row;
      });

      setRawDataSource(prev => {
        const newData = [...prev];
        newData[rowNumber].rows = newRows;
        return newData;
      });
    },
    [
      chartStyle.graphOptions.browseByCorpCode,
      chartStyle.graphOptions.scaleResults,
      chartStyle.lineConfigurations,
      memorizedBrowserByCorpCodeSelected,
      rowNumber,
      rawDataSource,
      editedCells,
      monthsArray,
      formatedData,
    ],
  );

  // Used to render the floating menu when right click cell
  const renderRowContextMenu = useCallback(
    (menuProps: any, { rowProps, cellProps }: any) => {
      const year = cellProps.data.year;
      const column = cellProps.id;

      const contryCode = chartStyle.graphOptions.browseByCorpCode
        ? memorizedBrowserByCorpCodeSelected
        : chartStyle.lineConfigurations[rowNumber].countryCodes[0];

      const tableRawData = data.graphs.find(graph => {
        const result = graph.lines.find(line => line.countryCodes[0] === contryCode);
        return result !== undefined ? result.title === rawDataSource[rowNumber].title : undefined;
      });

      const isTableEditable = is12MonthRollingAvg || isComparison ? false : tableRawData?.editable;

      if (column === 'year' || column === 'total' || column === 'percentage' || !isTableEditable) {
        return;
      }

      const yearMonth = `${year}${formatMonthNumber(monthsArray.findIndex(monthName => monthName === column) + 1)}`;

      let yearMonthsToBeEdited: string[] = [yearMonth];
      if (Object.entries(memoizedSelectedCells).length > 1) {
        yearMonthsToBeEdited = Object.keys(memoizedSelectedCells)
          .filter(cell => memoizedSelectedCells[cell])
          .map(cell => formatRowIdToYearMonth(cell, isFiscal));
      }

      // check if all selected cells are locked or unlocked
      const allSelectedCellsLocked = yearMonthsToBeEdited.every(yearMonth => lockedCells.includes(yearMonth));
      const allSelectedCellsUnlocked = yearMonthsToBeEdited.every(yearMonth => !lockedCells.includes(yearMonth));

      if (!allSelectedCellsLocked && !allSelectedCellsUnlocked) {
        menuProps.alignPositions = ['bl-tl'];
        menuProps.autoDismiss = true;
        menuProps.items = [
          {
            label: 'Lock all',
            onClick: () => lockUnlockValuesOnClick(true, yearMonthsToBeEdited),
          },
          {
            label: 'Unlock all',
            onClick: () => lockUnlockValuesOnClick(false, yearMonthsToBeEdited),
          },
        ];
      } else {
        menuProps.alignPositions = ['bl-tl'];
        menuProps.autoDismiss = true;
        menuProps.items = [
          {
            label: lockedCells.includes(yearMonth) ? 'Unlock' : 'Lock',
            onClick: () => lockUnlockValuesOnClick(!allSelectedCellsLocked, yearMonthsToBeEdited),
          },
        ];
      }
    },
    [
      chartStyle.graphOptions.browseByCorpCode,
      chartStyle.lineConfigurations,
      memorizedBrowserByCorpCodeSelected,
      rowNumber,
      data.graphs,
      is12MonthRollingAvg,
      isComparison,
      monthsArray,
      memoizedSelectedCells,
      rawDataSource,
      isFiscal,
      lockedCells,
      lockUnlockValuesOnClick,
    ],
  );

  const memoizedColumns = useMemo(() => {
    return headers(
      editedCells,
      lockedCells,
      loadingNewValues,
      chartStyle.lineColors[rowNumber]?.color ?? 'var(--primary)',
      isFiscal,
      data.totalAvgText,
      data.percentageChangeText,
      data.graphs[rowNumber].editable,
    );
  }, [
    editedCells,
    lockedCells,
    loadingNewValues,
    chartStyle.lineColors,
    rowNumber,
    isFiscal,
    data.totalAvgText,
    data.percentageChangeText,
  ]);

  //* EDITING RAW VALUES (NOT total OR percentage)
  const editRawValues = useCallback(
    async (
      code: number,
      formattedDataEntry:
        | {
            title: string;
            rows: FormattedRow[];
          }
        | undefined,
      rowIndex: number,
      rowToBeEdited: FormattedRow | undefined,
      predYrMoCell: string,
      oldValue: string,
    ) => {
      setLoadingNewValues(true);

      const allYearValues = Object.entries(formattedDataEntry?.rows?.[rowIndex] ?? []).flatMap((entry, i) => {
        const [month, value] = entry;
        if (month === 'id' || month === 'year' || month === 'total' || month === 'percentage') {
          return [];
        }
        const adjustedValue = parseNumberFromString(value.toString());
        return {
          yearMonth: Number(
            `${formattedDataEntry?.rows[rowIndex].year}${formatMonthNumber(
              monthsColumns.default.findIndex(monthName => monthName === month) + 1,
            )}`,
          ),
          value: chartStyle.graphOptions.scaleResults ? adjustedValue * 1000 : adjustedValue,
          isLocked: false,
        };
      });

      const totalValueLastYear = parseNumberFromString((formattedDataEntry?.rows[rowIndex - 1]?.total ?? 0).toString());

      const metaData = dataTypesMetadata.find(
        metaData => metaData.dataType === chartStyle.lineConfigurations[rowNumber].dataType,
      );

      if (rowToBeEdited && metaData?.annualAmountsType !== 'NONE') {
        rowToBeEdited['total'] = 'Loading...';
        rowToBeEdited['percentage'] = 'Loading...';
      }

      const nonOldValue = oldValue === null || oldValue === undefined || oldValue === '' || oldValue === '0';
      const newValue = allYearValues.find(yearValue => yearValue.yearMonth === Number(predYrMoCell))?.value;

      const contryCode = chartStyle.graphOptions.browseByCorpCode
        ? memorizedBrowserByCorpCodeSelected
        : chartStyle.lineConfigurations[rowNumber].countryCodes[0];

      const newTotalValues = await macroGraphService.editRawValues({
        countryCode: contryCode,
        action: nonOldValue ? 'CREATED' : newValue === 0 ? 'DELETED' : 'UPDATED',
        dataType: chartStyle.lineConfigurations[rowNumber].dataType,
        editedValue: Number(predYrMoCell),
        allYearValues,
        lastYearTotalValue: chartStyle.graphOptions.scaleResults ? totalValueLastYear * 1000 : totalValueLastYear,
        scaled: chartStyle.graphOptions.scaleResults,
        currencyOptions: { ...chartStyle.lineConfigurations[rowNumber].currencyOptions },
      });

      await updateRealTimeTableData();

      if (rowToBeEdited && metaData?.annualAmountsType !== 'NONE') {
        rowToBeEdited['total'] = Number(
          newTotalValues.value.toFixed(newTotalValues.value < 100 ? 2 : 0),
        ).toLocaleString();

        const valueToShow = `${Number((newTotalValues.percentage! * 100).toFixed(1))}%`;
        rowToBeEdited['percentage'] =
          typeof newTotalValues.percentage === 'number'
            ? newTotalValues.percentage < 0
              ? valueToShow
              : `${valueToShow}`
            : '-';
      }
    },
    [
      dataTypesMetadata,
      chartStyle.graphOptions.browseByCorpCode,
      chartStyle.graphOptions.scaleResults,
      chartStyle.lineConfigurations,
      memorizedBrowserByCorpCodeSelected,
      rowNumber,
      updateRealTimeTableData,
    ],
  );

  const editTotal = useCallback(
    async (
      predYrMoToBeEdited: string[],
      formattedDataEntry: { title: string; rows: FormattedRow[] },
      rowIndex: number,
      columnId: string,
    ) => {
      setLoadingNewValues(true);
      const copyOfTableValues = structuredClone(formattedDataEntry);

      const totalValue =
        columnId === 'total'
          ? parseNumberFromString((copyOfTableValues?.rows[rowIndex]?.total ?? 0).toString())
          : undefined;
      const percentage =
        columnId === 'percentage'
          ? parseFloat(
              (copyOfTableValues?.rows[rowIndex]?.percentage ?? 0)
                .toString()
                .replaceAll('%', '')
                .replaceAll('+', '')
                .replaceAll(' ', ''),
            ) / 100
          : undefined;

      // check if totalValue of percentage are not NaN as well
      if (
        (totalValue === undefined && percentage === undefined) ||
        (totalValue !== undefined && isNaN(totalValue)) ||
        (percentage !== undefined && isNaN(percentage))
      ) {
        return;
      }

      // set loading to true
      predYrMoToBeEdited.forEach(yearMonth => {
        const monthIndex = yearMonth.slice(4, 6);
        const columnToBeEdited = monthsArray[parseInt(monthIndex, 10) - 1];
        const rowToBeEdited = formattedDataEntry.rows.find(row => row.id === `${rowNumber}-${yearMonth.slice(0, 4)}`);
        if (!rowToBeEdited) {
          return;
        }
        // @ts-ignore
        rowToBeEdited[columnToBeEdited] = 'Loading...';
      });
      // total or percentage
      const rowToBeEdited = formattedDataEntry?.rows[rowIndex];
      if (!rowToBeEdited) {
        return;
      }

      // Get values from the table to create the payload
      const year = copyOfTableValues?.rows[rowIndex]?.year;
      const allYearValues = Object.entries(copyOfTableValues?.rows?.[rowIndex] ?? []).flatMap((entry, i) => {
        const [month, value] = entry;
        if (month === 'id' || month === 'year' || month === 'total' || month === 'percentage') {
          return [];
        }
        const adjustedValue = parseNumberFromString(value.toString());
        return {
          yearMonth: Number(`${year}${formatMonthNumber(monthsArray.findIndex(monthName => monthName === month) + 1)}`),
          value: chartStyle.graphOptions.scaleResults ? adjustedValue * 1000 : adjustedValue,
          isLocked: false,
        };
      });
      const totalValueLastYear = parseNumberFromString((copyOfTableValues?.rows[rowIndex - 1]?.total ?? 0).toString());

      const selectedValues: EditTotalSelectedValue[] = predYrMoToBeEdited.map(yearMonth => {
        return { yearMonth: Number(yearMonth), action: 'UPDATED' };
      });

      const oldTotal = rowToBeEdited['total'];
      const oldPercentage = rowToBeEdited['percentage'];

      rowToBeEdited['total'] = 'Loading...';
      rowToBeEdited['percentage'] = 'Loading...';

      const contryCode = chartStyle.graphOptions.browseByCorpCode
        ? memorizedBrowserByCorpCodeSelected
        : chartStyle.lineConfigurations[rowNumber].countryCodes[0];

      try {
        const newTotalValues = await macroGraphService.editTotalValue({
          countryCode: contryCode,
          dataType: chartStyle.lineConfigurations[rowNumber].dataType,
          newValue: {
            value:
              totalValue !== undefined
                ? chartStyle.graphOptions.scaleResults
                  ? totalValue * 1000
                  : totalValue
                : undefined,
            percentage,
          },
          selectedValues: selectedValues,
          allYearValues,
          lastYearTotalValue: chartStyle.graphOptions.scaleResults ? totalValueLastYear * 1000 : totalValueLastYear,
          scaled: chartStyle.graphOptions.scaleResults,
          currencyOptions: { ...chartStyle.lineConfigurations[rowNumber].currencyOptions },
        });

        await updateRealTimeTableData();

        // Set results to the table
        rowToBeEdited['total'] = Number(newTotalValues.newTotal.value.toFixed(0)).toLocaleString();

        if (typeof newTotalValues.newTotal.percentage === 'number') {
          const value = newTotalValues.newTotal.percentage;
          rowToBeEdited['percentage'] = value > 0 ? `+${(value * 100).toFixed(1)}%` : `${(value * 100).toFixed(1)}%`;
        } else {
          rowToBeEdited['percentage'] = '-';
        }

        newTotalValues.newSelectedValues.forEach(yearMonth => {
          const monthIndex = String(yearMonth.yearMonth).slice(4, 6);
          const columnToBeEdited = monthsArray[parseInt(monthIndex, 10) - 1];
          const rowToBeEdited = formattedDataEntry.rows.find(
            row => row.id === `${rowNumber}-${String(yearMonth.yearMonth).slice(0, 4)}`,
          );
          if (!rowToBeEdited) {
            return;
          }
          // @ts-ignore
          rowToBeEdited[columnToBeEdited] = yearMonth.value.toFixed(0);
        });
        setEditedCells(prev => [...prev, ...predYrMoToBeEdited]);
      } catch (error: any) {
        const axiosError = error as AxiosError;
        if (axiosError.response) NormalAlert(axiosError.response.data.error);
        else NormalAlert('Error when trying to edit the total/percentage');

        rowToBeEdited['total'] = oldTotal;
        rowToBeEdited['percentage'] = oldPercentage;
      }
    },
    [
      chartStyle.graphOptions.browseByCorpCode,
      chartStyle.graphOptions.scaleResults,
      chartStyle.lineConfigurations,
      memorizedBrowserByCorpCodeSelected,
      rowNumber,
      updateRealTimeTableData,
      monthsArray,
    ],
  );

  // Function to format selected cells in Excel-friendly format (Tab-Separated Values)
  const formatSelectedCellsForClipboard = useCallback(() => {
    const selectedRows: string[][] = [];
    const selectedCellsArray = Object.keys(selectedCells).filter(cell => selectedCells[cell]);

    if (!formatedData || !formatedData[rowNumber] || !formatedData[rowNumber].rows) {
      return '';
    }

    // Get the selected rows and columns
    selectedCellsArray.forEach(cell => {
      const [rowId, columnId] = cell.split(',');
      const rowIndex = formatedData[rowNumber].rows.findIndex(row => row.id === rowId);
      const row = formatedData[rowNumber].rows[rowIndex];

      if (!row) {
        return;
      }

      // Create a new row if it doesn't exist
      if (!selectedRows[rowIndex]) {
        selectedRows[rowIndex] = [];
      }

      // Add the cell value to the row
      selectedRows[rowIndex][monthsColumns.default.findIndex(month => month === columnId)] = row[
        columnId as keyof FormattedRow
      ] as string;
    });

    // remove empty rows
    const cleanSelectedRows = selectedRows.filter(row => row.length !== 0);

    // Remove empty columns
    const cleanSelectedCells = cleanSelectedRows.map(row => row.filter(cell => cell !== undefined));

    // Convert the selectedRows into Tab-Separated Values (TSV)
    const clipboardContent = cleanSelectedCells
      .map(row => row.join('\t')) // Join columns with tabs
      .join('\n'); // Join rows with newline

    return clipboardContent;
  }, [selectedCells, formatedData, rowNumber]);

  const getNextEditableCell = useCallback(
    (fromCell: string) => {
      if (!formatedData || !formatedData[rowNumber] || !formatedData[rowNumber].rows) {
        return;
      }

      const [rowId, columnId] = fromCell.split(',');

      const rowIndex = formatedData[rowNumber].rows.findIndex(row => row.id === rowId);
      const columnIndex = monthsArray.findIndex(month => month === columnId);

      const year = formatedData?.[rowNumber]?.rows?.[rowIndex]?.year;
      const month = monthsArray[columnIndex];

      const monthNumber = monthsColumns.default.indexOf(month);

      const predYrMo = getPredYrMo(year, monthNumber, isFiscal);

      const currentCellIndex = unlockedCells.findIndex(cell => cell === predYrMo);

      let nextRowId = rowId;
      let nextColumnId = columnId;
      let nextRowIndex = rowIndex;

      if (lastClickedButtonRef.current === 'tab') {
        const nextCell = unlockedCells[currentCellIndex + 1];

        if (!nextCell) {
          isEditingCell.current = false;
          return;
        }

        nextRowId = `${rowNumber}-${nextCell.slice(0, 4)}`;
        nextColumnId = monthsArray[parseInt(nextCell.slice(4, 6), 10) - 1];

        nextRowIndex = formatedData[rowNumber].rows.findIndex(row => row.id === nextRowId);
      } else if (lastClickedButtonRef.current === 'enter') {
        if (columnId === 'total' || columnId === 'percentage') {
          return;
        }
        // get next cell that has the same month as the current cell and is > current cell index
        const nextCell = unlockedCells.find((cell, index) => {
          // need to add +1 since monthNumber is 0 based
          return index > currentCellIndex && Number(cell.slice(4, 6)) === monthNumber + 1;
        });

        console.log('nextCell', nextCell);

        if (!nextCell) {
          isEditingCell.current = false;
          return;
        }

        nextRowId = `${rowNumber}-${nextCell.slice(0, 4)}`;
        nextColumnId = monthsArray[parseInt(nextCell.slice(4, 6), 10) - 1];

        nextRowIndex = formatedData[rowNumber].rows.findIndex(row => row.id === nextRowId);
      }

      return { rowId: nextRowId, rowIndex: nextRowIndex, columnId: nextColumnId };
    },
    [formatedData, isFiscal, monthsArray, rowNumber, unlockedCells],
  );

  const isEditingCell = useRef(false);
  // Function to handle key down event
  const handleKeyDown = useCallback(
    (event: any) => {
      console.log('isEditing: ', isEditingCell.current);
      if (
        // event.key === 'Enter' ||
        // event.key === 'NumpadEnter' ||
        (event.key === 'Backspace' ||
          event.key === '1' ||
          event.key === '2' ||
          event.key === '3' ||
          event.key === '4' ||
          event.key === '5' ||
          event.key === '6' ||
          event.key === '7' ||
          event.key === '8' ||
          event.key === '9' ||
          event.key === '0') &&
        !isEditingCell.current &&
        gridRef &&
        gridRef.current
      ) {
        if (memoizedSelectedCells && Object.keys(memoizedSelectedCells).length === 1) {
          const [rowId, columnId] = Object.entries(memoizedSelectedCells)?.[0][0].split(','); // Assuming single selection
          const rowIndex = formatedData?.[rowNumber].rows.findIndex(row => row.id === rowId) ?? 0;
          if (gridRef.current.startEdit) {
            const rowId = formatedData?.[rowNumber].rows?.[rowIndex]?.id;
            const formattedDataEntry = formatedData?.[rowNumber];
            if (!formattedDataEntry || !rowId) {
              return;
            }
            const rowToBeEdited = formattedDataEntry.rows.find(row => row.id === rowId);
            if (!rowToBeEdited) {
              return;
            }

            const value =
              event.key === 'Enter' || event.key === 'NumpadEnter'
                ? undefined
                : event.key === 'Backspace'
                ? ''
                : event.key;
            gridRef?.current?.startEdit?.({ rowIndex, columnId, value });
          }
        }
      }
      // Is not editing and click arrow keys to move selection
      else if (
        !isEditingCell.current &&
        gridRef &&
        gridRef.current &&
        (event.key === 'ArrowDown' ||
          event.key === 'ArrowUp' ||
          event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight')
      ) {
        if (!formatedData || !formatedData[rowNumber] || !formatedData[rowNumber].rows) {
          return;
        }

        const firstSelectedCell = Object.keys(memoizedSelectedCells)[0];

        if (!firstSelectedCell) {
          return;
        }

        const [rowId, columnId] = firstSelectedCell.split(',');
        const rowIndex = formatedData?.[rowNumber].rows.findIndex(row => row.id === rowId) ?? 0;
        const columnIndex = monthsArray.findIndex(month => month === columnId);
        const nextRowIndex =
          event.key === 'ArrowDown' ? rowIndex + 1 : event.key === 'ArrowUp' ? rowIndex - 1 : rowIndex;
        const nextColumnIndex =
          event.key === 'ArrowRight' ? columnIndex + 1 : event.key === 'ArrowLeft' ? columnIndex - 1 : columnIndex;

        if (
          nextRowIndex < 0 ||
          nextRowIndex >= formatedData?.[rowNumber].rows.length ||
          nextColumnIndex < 0 ||
          nextColumnIndex >= monthsArray.length
        ) {
          return;
        }

        setCombinedSelectedCells(prev => {
          const newSelectedCells = produce(prev, draft => {
            draft[rowNumber] = {
              [`${formatedData?.[rowNumber].rows[nextRowIndex].id},${monthsArray[nextColumnIndex]}`]: true,
            };
          });
          return newSelectedCells;
        });
      }
      // Is editing and click enter or tab to finish editing and move to the next cell
      else if (isEditingCell.current) {
        let hasClickedEnterOrTab = false;
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
          lastClickedButtonRef.current = 'enter';
          hasClickedEnterOrTab = true;
        } else if (event.key === 'Tab') {
          lastClickedButtonRef.current = 'tab';
          hasClickedEnterOrTab = true;
        } else {
          lastClickedButtonRef.current = undefined;
          return;
        }

        if (hasClickedEnterOrTab && gridRef && gridRef.current) {
          const nextEditableCellId = getNextEditableCell(
            `${actualEditableCell.current?.rowId},${actualEditableCell.current?.columnId}`,
          );
          nextEditableCell.current = nextEditableCellId;

          isEditingCell.current = false;
          hasClickedEnterOrTab = false;
        }
      }
    },
    [
      gridRef,
      memoizedSelectedCells,
      formatedData,
      rowNumber,
      monthsArray,
      setCombinedSelectedCells,
      getNextEditableCell,
    ],
  );

  const pasteFromExcelFormat = useCallback(() => {
    if (gridRef && gridRef.current && Object.keys(memoizedSelectedCells).length >= 1) {
      if (navigator.clipboard) {
        navigator.clipboard.readText().then(async clipboardText => {
          const clipboardRows = clipboardText
            .replaceAll('\r', '')
            .split('\n')
            .map(row => row.split('\t'));
          const firstSelectedCell = Object.keys(memoizedSelectedCells)[0];

          console.log('paste content:', clipboardRows);

          if (!formatedData || !formatedData[rowNumber] || !formatedData[rowNumber].rows) {
            return;
          }

          const [rowId, columnId] = firstSelectedCell.split(',');
          const firstRowIndex = formatedData[rowNumber].rows.findIndex(row => row.id === rowId);
          const firstColumnIndex = monthsColumns.default.findIndex(month => month === columnId);

          const formattedDataEntry = formatedData[rowNumber];
          if (!formattedDataEntry) {
            return;
          }

          const firstRowToBeEdited = formattedDataEntry.rows.find(row => row.id === rowId);

          if (!firstRowToBeEdited) {
            return;
          }

          const editedCells: string[] = [];
          clipboardRows.forEach((row, i) => {
            // check if rowIndex + i is outside the bounds of the row
            if (firstRowIndex + i >= formattedDataEntry.rows.length) {
              return;
            }

            const rowToBeEdited = formattedDataEntry.rows[firstRowIndex + i];
            const actualRowId = rowToBeEdited.id;
            row.forEach((cell, j) => {
              const month = monthsColumns.default[j + firstColumnIndex];
              // check if cell is locked
              if (lockedCells.includes(formatRowIdToYearMonth(`${actualRowId},${month}`, isFiscal))) {
                return;
              }

              const value = parseNumberFromString(cell);
              if (!isNaN(value)) {
                rowToBeEdited['total'] = 'Loading...';
                rowToBeEdited['percentage'] = 'Loading...';

                const yearMonth = formatRowIdToYearMonth(`${actualRowId},${month}`, isFiscal);
                editedCells.push(yearMonth);
                setEditedCells(prev => [...prev, yearMonth]);
                const isPercentageLine = chartStyle?.lineConfigurations[firstRowIndex + i]?.showAsPercentage;
                const changedToPercentage = rawDataSource?.[firstRowIndex + i]?.changedToPercentage;
                const smallNumber = value < 100;

                console.log('value:', value);
                // @ts-ignore
                rowToBeEdited[month] = value.toFixed(changedToPercentage || isPercentageLine || smallNumber ? 2 : 0);
              }
            });
            formattedDataEntry.rows[firstRowIndex + i] = rowToBeEdited;
          });

          const newTotalValues = await macroGraphService.editMultipleRawValues({
            countryCode: chartStyle.lineConfigurations[rowNumber].countryCodes[0],
            dataType: chartStyle.lineConfigurations[rowNumber].dataType,
            editedValues: editedCells.map(yrMo => Number(yrMo)),
            scaled: chartStyle.graphOptions.scaleResults,
            currencyOptions: { ...chartStyle.lineConfigurations[rowNumber].currencyOptions },
            allValues: formattedDataEntry.rows.map(row => {
              return Object.entries(row).flatMap(([month, value]) => {
                if (month === 'id' || month === 'year' || month === 'total' || month === 'percentage') {
                  return [];
                }
                const adjustedValue = parseNumberFromString(value.toString());
                return {
                  yearMonth: Number(
                    `${formattedDataEntry.rows.find(r => r.id === row.id)?.year}${formatMonthNumber(
                      monthsColumns.default.findIndex(monthName => monthName === month) + 1,
                    )}`,
                  ),
                  value: chartStyle.graphOptions.scaleResults ? adjustedValue * 1000 : adjustedValue,
                  isLocked: false,
                };
              });
            }),
          });

          formatedData[rowNumber].rows.forEach((row, index) => {
            const rowToBeEdited = row;
            if (!rowToBeEdited) {
              return;
            }
            const totals = newTotalValues?.[index];
            let percentage = 0;
            if (totals.percentage !== null) {
              percentage = totals.percentage;
            }

            rowToBeEdited['total'] = newTotalValues?.[index]?.value ? newTotalValues[index].value.toFixed(0) : '-';
            rowToBeEdited['percentage'] = percentage === 0 ? '-' : `${(percentage * 100).toFixed(1)}%`;
          });

          updateRealTimeTableData();
        });
      }
    }
  }, [
    gridRef,
    memoizedSelectedCells,
    formatedData,
    rowNumber,
    chartStyle.lineConfigurations,
    chartStyle.graphOptions.scaleResults,
    updateRealTimeTableData,
    lockedCells,
    isFiscal,
    rawDataSource,
  ]);

  useCopyOrPasteShortcut(formatSelectedCellsForClipboard(), pasteFromExcelFormat);

  // Attach the event listener once the grid is ready
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const doubleCheckSelectedCell = useRef<string>('initial');
  useEffect(() => {
    if (Object.keys(memoizedSelectedCells).length === 1 && cellSelectionCount.current > 1) {
      doubleCheckSelectedCell.current = Object.keys(memoizedSelectedCells)[0];
    } else {
      cellSelectionCount.current = cellSelectionCount.current + 1;
    }
  }, [memoizedSelectedCells]);

  const actualEditableCell = useRef<
    | {
        rowId: string | undefined;
        rowIndex: number;
        columnId: string;
      }
    | undefined
  >();

  const nextEditableCell = useRef<
    | {
        rowId: string;
        rowIndex: number;
        columnId: string;
      }
    | undefined
  >();

  const totalRows = useMemo(() => formatedData?.reduce((acc, table) => acc + table.rows.length, 0), [formatedData]);
  const isEditingTotal = useRef(false);
  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }: any) => {
      const finalValue = value === '' || !value ? '0' : value;
      const rowId = formatedData?.[rowNumber].rows[rowIndex].id;
      const formattedDataEntry = formatedData?.[rowNumber];

      if (!formattedDataEntry) {
        return;
      }
      const rowToBeEdited = formattedDataEntry.rows.find(row => row.id === rowId);
      if (!rowToBeEdited) {
        return;
      }

      // get next editable cell and set it to nextEditableCell
      actualEditableCell.current = { rowId, rowIndex, columnId };

      // return if new value is the same as the old value
      if (rowToBeEdited[columnId as keyof FormattedRow] === finalValue) {
        nextEditableCell.current = undefined;
        isEditingCell.current = false;
        return;
      }

      if (columnId === 'total' || columnId === 'percentage') {
        const metaData = dataTypesMetadata.find(
          metaData => metaData.dataType === chartStyle.lineConfigurations[rowNumber].dataType,
        );

        if (metaData?.annualAmountsType === 'NONE') {
          rowToBeEdited[columnId as keyof FormattedRow] = '-';
          return false;
        }
      }

      const predYrMoCell = `${formattedDataEntry.rows[rowIndex].year}${formatMonthNumber(
        monthsColumns.default.findIndex(month => month === columnId) + 1,
      )}`;

      if (columnId !== 'year' && columnId !== 'total' && columnId !== 'percentage') {
        isEditingTotal.current = false;
        const oldValue = rowToBeEdited[columnId as keyof FormattedRow].toString();
        rowToBeEdited[columnId as keyof FormattedRow] = finalValue;
        await editRawValues(0, formattedDataEntry, rowIndex, rowToBeEdited, predYrMoCell, oldValue);
        setEditedCells(prev => [...prev, predYrMoCell]);
      } else if (columnId === 'total' || columnId === 'percentage') {
        isEditingTotal.current = true;

        rowToBeEdited[columnId as keyof FormattedRow] = finalValue;

        const cellsToBeEdited = Object.entries(memoizedSelectedCells).flatMap(([cell, isSelected]) => {
          if (isSelected) {
            const [rowId, columnId] = cell.split(',');
            if (columnId === 'year') {
              return [];
            }

            return `${formattedDataEntry?.rows.find(row => row.id === rowId)?.year}${formatMonthNumber(
              monthsArray.findIndex(monthName => monthName === columnId) + 1,
            )}`;
          }
          return [];
        });

        await editTotal(cellsToBeEdited, formattedDataEntry, rowIndex, columnId);
      }

      setChartDataIsDirty(true);
      cellSelectionCount.current = 2;

      setTimeout(() => {
        setCombinedSelectedCells(prev => {
          const newSelectedCells = produce(prev, draft => {
            draft[rowNumber] = {
              [`${nextEditableCell.current?.rowId},${nextEditableCell.current?.columnId}`]: true,
            };
          });
          return newSelectedCells;
        });
        nextEditableCell.current = undefined;

        // set isEditing to false if user complete the edit with clicking outside the cell
        if (!lastClickedButtonRef.current) {
          isEditingCell.current = false;
        }
      }, 10);
      setTimeout(() => {
        setLoadingNewValues(false);

        if (isEditingTotal.current) {
          isEditingCell.current = false;
        }
      }, 5);
    },
    [
      formatedData,
      rowNumber,
      setChartDataIsDirty,
      dataTypesMetadata,
      chartStyle.lineConfigurations,
      editRawValues,
      memoizedSelectedCells,
      editTotal,
      monthsArray,
      setCombinedSelectedCells,
    ],
  );

  useEffect(() => {
    const selected = Object.keys(memoizedSelectedCells).filter(cell => memoizedSelectedCells[cell]);

    if (selected.length === 0) {
      setSelectedTotalSum(0);
      return;
    }

    const [rowId, columnId] = selected?.[0]?.split(',');
    const [rowIdNumber] = rowId?.split('-');

    if (columnId === 'year') {
      return;
    }

    if (!rowIdNumber || Number(rowIdNumber) !== rowNumber) {
      return;
    }

    const totalSum = selected.reduce((acc, cell) => {
      const [rowId, columnId] = cell.split(',');
      const [rowIdNumber] = rowId.split('-');

      if (columnId === 'year') {
        return acc;
      }

      if (Number(rowIdNumber) !== rowNumber) {
        return acc;
      }

      const formattedDataEntry = formatedData?.[rowNumber];
      if (!formattedDataEntry) {
        return acc;
      }

      //@ts-ignore
      const value = formattedDataEntry.rows.find(row => row.id === rowId)?.[columnId];
      const result = value?.includes('-')
        ? acc - parseNumberFromString((value ?? '-').replaceAll('-', '0'))
        : acc + parseNumberFromString(value);
      return result;
    }, 0);

    setSelectedTotalSum(totalSum);
  }, [formatedData, memoizedSelectedCells]);

  const onCellSelectionChange = useCallback(
    (newSelectedCells: Record<string, boolean>) => {
      const newCellsColumn = Object.keys(newSelectedCells).flatMap(cell =>
        !memoizedSelectedCells[cell] ? [cell.split(',')[1]] : [],
      );

      if (newCellsColumn.length === 0) {
        setCombinedSelectedCells(initialSelectedCells);
        return;
      }

      const [rowId] = Object.keys(newSelectedCells)[0]?.split(',');

      if (!rowId) {
        return;
      } else if (
        (newCellsColumn.includes('year') ||
          newCellsColumn.includes('total') ||
          newCellsColumn.includes('percentage')) &&
        Object.keys(combinedSelectedCells[rowNumber]).length > 1 &&
        Object.keys(combinedSelectedCells[rowNumber])
          .map(cell => cell.split(',')[0])
          .includes(rowId)
      ) {
        // unselect rows that are not the same as rowId
        const newSelectedCellsObj = Object.entries(combinedSelectedCells[rowNumber]).reduce(
          (acc, [cell, isSelected]) => {
            const [cellRowId] = cell.split(',');
            const currentYYYYMM = moment().format('YYYYMM');
            if (cellRowId === rowId && formatRowIdToYearMonth(cell, isFiscal) >= currentYYYYMM) {
              acc[cell] = isSelected;
            }
            if (lockedCells.includes(formatRowIdToYearMonth(cell, isFiscal))) {
              acc[cell] = false;
            }

            return acc;
          },
          {} as Record<string, boolean>,
        );

        const newCombinedSelectedCells = produce(initialSelectedCells, draft => {
          draft[rowNumber] = newSelectedCellsObj;
        });
        setCombinedSelectedCells(newCombinedSelectedCells);

        return;
      } else if (
        (newCellsColumn.includes('year') ||
          newCellsColumn.includes('total') ||
          newCellsColumn.includes('percentage')) &&
        newCellsColumn.length === 1
      ) {
        // select all values for that year

        const formattedDataEntry = formatedData?.[rowNumber];
        if (!formattedDataEntry) {
          return;
        }
        const newSelectedCellsObj = monthsArray.reduce((acc, cell) => {
          acc[`${rowId},${cell}`] = true;
          return acc;
        }, {} as Record<string, boolean>);
        const newCombinedSelectedCells = produce(initialSelectedCells, draft => {
          draft[rowNumber] = newSelectedCellsObj;
        });
        setCombinedSelectedCells(newCombinedSelectedCells);
        return;
      } else if (
        (newCellsColumn.includes('total') || newCellsColumn.includes('percentage')) &&
        newCellsColumn.length > 1
      ) {
        return;
      } else {
        const newCombinedSelectedCells = produce(initialSelectedCells, draft => {
          draft[rowNumber] = newSelectedCells;
        });
        setCombinedSelectedCells(newCombinedSelectedCells);
      }
    },
    [
      combinedSelectedCells,
      rowNumber,
      memoizedSelectedCells,
      setCombinedSelectedCells,
      lockedCells,
      isFiscal,
      formatedData,
      monthsArray,
    ],
  );

  const startRealCellEdition = useRef(false);
  const onEditStart = useCallback(
    ({ rowIndex, columnId }: any) => {
      const formattedDataEntry = formatedData?.[rowNumber];
      if (!formattedDataEntry) {
        return;
      }

      // se celula atual for a proxima editavel cancela a edicao
      if (
        `${nextEditableCell.current?.rowId},${nextEditableCell.current?.columnId}` ===
        `${formattedDataEntry.rows[rowIndex].id},${columnId}`
      ) {
        setTimeout(async () => {
          const cancelEdit = () =>
            new Promise<void>(resolve => {
              gridRef?.current?.cancelEdit?.({ columnId, rowIndex });
              setTimeout(() => {
                resolve();
              }, 50);
            });

          await cancelEdit();

          nextEditableCell.current = undefined;
          isEditingCell.current = false;
        }, 1);
        return;
      }

      startRealCellEdition.current = true;
      isEditingCell.current = true;
    },
    [formatedData, gridRef, rowNumber],
  );

  const [loadingTable, setLoadingTable] = useState(true);
  const totalTables = useMemo(() => formatedData?.length, [formatedData]);
  useEffect(() => {
    // instant set to false if we have less than MAX_TOTAL_ROWS_WITHOUT_LOADING
    if (totalRows && totalRows < MAX_TOTAL_ROWS_WITHOUT_LOADING) {
      setLoadingTable(false);
      setLoadingRealTimeTableData(false);
      return;
    }

    // Check all conditions
    const allConditionsMet =
      formatedData !== null &&
      formatedData !== undefined &&
      formatedData.length > 0 &&
      formatedData[rowNumber] !== undefined &&
      formatedData[rowNumber].rows !== undefined &&
      formatedData[rowNumber].rows.length > 0 &&
      tablesResult !== null &&
      !fetchingTableStructure &&
      memoizedColumns.length > 12;

    if (allConditionsMet) {
      const constantWaitTime =
        loadingRealTimeTableData && !loadingTable
          ? SWITCH_COUNTRY_WAIT_TIME_FOR_EVERY_10_ROWS
          : INITIAL_LOADING_TIME_FOR_EVERY_10_ROWS;
      const loadingWaitTime = (constantWaitTime * (totalRows ?? 1)) / 10;
      const timer = setTimeout(() => {
        setLoadingTable(false);
        setLoadingRealTimeTableData(false);
      }, loadingWaitTime);

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [formatedData, rowNumber, tablesResult, memoizedColumns, fetchingTableStructure]);

  return formatedData !== null &&
    formatedData !== undefined &&
    formatedData.length > 0 &&
    formatedData[rowNumber] !== undefined &&
    formatedData[rowNumber].rows !== undefined &&
    formatedData[rowNumber].rows.length > 0 &&
    tablesResult !== null &&
    memoizedColumns.length > 12 ? (
    <>
      {(loadingTable || loadingRealTimeTableData) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: '10px',
            alignItems: 'center',
            height: '100%',
            width: 'calc(100% + 20px)',
            zIndex: 1000,
            position: 'absolute',
            paddingTop: '40px',
            fontWeight: 'bold',
            left: -20,
            bottom: 0,
            background: `rgba(255, 255, 255, ${totalTables ?? 0 > 1 ? 0.4 : 0.8})`,
          }}>
          <LogoLoading />
          <p
            style={{
              zIndex: 1000,
              background: `rgba(255, 255, 255, 1)`,
            }}>
            Almost there! Large data is loading and will be ready shortly.
          </p>
        </div>
      )}
      <div className="table-container">
        {chartStyle.graphOptions.scaleResults && rowNumber === 0 ? (
          <div>All values from table and graph are scaled (000)</div>
        ) : (
          <></>
        )}
        <div
          style={{
            color: 'white',
            width: '100%',
            height: '40px',
            backgroundColor: chartStyle.lineColors[rowNumber]?.color ?? 'var(--primary)',
            padding: '10px 18px',
          }}>
          {realTimeDataRows ? realTimeDataRows[rowNumber].title : formatedData?.[rowNumber]?.title}
        </div>

        <DataGridMemo
          formatedData={formatedData}
          rowNumber={rowNumber}
          renderRowContextMenu={renderRowContextMenu}
          memoizedSelectedCells={memoizedSelectedCells}
          onCellSelectionChange={onCellSelectionChange}
          memoizedColumns={memoizedColumns}
          setGridRef={setGridRef}
          onEditStart={onEditStart}
          onEditComplete={onEditComplete}
          tableHasChanged={tableHasChanged}
          styleCalendars={chartStyle.calendars}
          scaleResults={chartStyle.graphOptions.scaleResults}
          stopEditingCell={() => {
            if (nextEditableCell.current || startRealCellEdition.current) {
              startRealCellEdition.current = false;
              return;
            }

            console.log('stop editing cell');
            isEditingCell.current = false;
            setLoadingNewValues(false);
          }}
        />
      </div>
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '100%',
        width: 'calc(100% + 20px)',
        zIndex: 1000,
        position: 'absolute',
        paddingTop: '40px',
        left: -20,
        bottom: 0,
        background: `rgba(255, 255, 255, ${totalTables ?? 0 > 1 ? 0.4 : 0.8})`,
      }}>
      <LogoLoading />
    </div>
  );
};

const DataGridMemo = React.memo(
  ({
    formatedData,
    rowNumber,
    renderRowContextMenu,
    memoizedSelectedCells,
    onCellSelectionChange,
    memoizedColumns,
    setGridRef,
    onEditStart,
    onEditComplete,
    stopEditingCell,
  }: {
    formatedData: FormattedDataType;
    rowNumber: number;
    renderRowContextMenu: any;
    memoizedSelectedCells: Record<string, boolean>;
    onCellSelectionChange: (newSelectedCells: Record<string, boolean>) => void;
    memoizedColumns: any;
    setGridRef: (ref: MutableRefObject<TypeComputedProps | null>) => void;
    onEditStart: (params: { rowIndex: number; columnId: string }) => void;
    onEditComplete: (params: { value?: string; columnId: string; rowIndex: number }) => void;
    tableHasChanged: boolean;
    styleCalendars: string;
    scaleResults: boolean;
    stopEditingCell: () => void;
  }) => {
    const totalRows = useMemo(() => formatedData?.reduce((acc, table) => acc + table.rows.length, 0), [formatedData]);
    const tableHeight = useMemo(() => {
      let height = (formatedData?.[rowNumber]?.rows ?? []).length * 40 + 43;

      if (totalRows && totalRows < MAX_TOTAL_ROWS_WITHOUT_MAX_HEIGHT) {
        return height;
      }

      return height > TABLE_MAX_HEIGHT ? TABLE_MAX_HEIGHT : height;
    }, [formatedData, rowNumber, totalRows]);
    return (
      <ReactDataGrid
        onReady={setGridRef}
        idProperty="id"
        style={{
          maxWidth: '100%',
          height: tableHeight,
          minHeight: tableHeight,
        }}
        showColumnMenuLockOptions={false}
        showColumnMenuTool
        renderRowContextMenu={renderRowContextMenu}
        cellSelection={memoizedSelectedCells}
        onCellSelectionChange={onCellSelectionChange}
        columns={memoizedColumns}
        dataSource={formatedData?.[rowNumber]?.rows}
        onEditStart={onEditStart}
        onEditComplete={onEditComplete}
        editable
        virtualized
        activeCell={null}
        allowRowTabNavigation={false}
        autoFocusOnEditComplete={false}
        onEditCancel={() => {
          stopEditingCell();
        }}
        onEditStop={() => {
          stopEditingCell();
        }}
      />
    );
  },
  (prevProps, nextProps) => {
    const browseByCorpCodeChanged = prevProps.formatedData !== nextProps.formatedData;
    const styleCalendarsChanged = prevProps.styleCalendars !== nextProps.styleCalendars;
    const scaleResultsChanged = prevProps.scaleResults !== nextProps.scaleResults;
    const memoizedColumnsChanged = prevProps.memoizedColumns !== nextProps.memoizedColumns;
    // check if this table need to update selected cells
    if (
      nextProps.tableHasChanged ||
      browseByCorpCodeChanged ||
      styleCalendarsChanged ||
      scaleResultsChanged ||
      memoizedColumnsChanged
    ) {
      return false; // re-render because selected cells have changed
    }
    return true; // don't re-render
  },
);

export default React.memo(EditableYearMonthTable);
