import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './index.scss';

const UserSignedOutComponent: React.FC = () => {
  return (
    <Row className="user-signed-out">
      <Col className="sign-in">
        <Link className="sign-in" to="/login">
          Sign In
        </Link>
      </Col>
      <Col className="picture-container">
        <div className="picture">
          <Image src="/assets/user-icon.svg" width="16" height="16" />
        </div>
      </Col>
    </Row>
  );
};

export default UserSignedOutComponent;
