import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import LoadingComponent from '../loading';
import './index.scss';

type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'text-black'
  | 'text-gray'
  | 'detail-gray'
  | 'detail-border'
  | 'warning-error'
  | 'warning-success'
  | 'warning-orange'
  | 'warning-blue'
  | 'warning-disable'
  | 'bg-white'
  | 'bg-gray'
  | 'bg-yellow'
  | 'bg-red'
  | 'bg-green'
  | 'bg-blue'
  | 'bg-black'
  | 'bg-transparent';

export interface ButtonProps {
  children: ReactNode;
  variant?: 'primary' | 'link';
  loading?: boolean;
  disabled?: boolean;
  onClick?: VoidFunction;
  backgroundColor?: ColorTypes;
  fontColor?: ColorTypes;
  style?: CSSProperties;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  loading = false,
  disabled = false,
  onClick,
  backgroundColor = 'primary',
  fontColor = 'bg-white',
  style,
  className,
}) => {
  const [bgColor, setBgColor] = useState(backgroundColor);
  useEffect(() => {
    if (variant === 'link') {
      setBgColor('bg-transparent');
    } else {
      setBgColor(backgroundColor);
    }
  }, [backgroundColor, variant]);

  return (
    <button
      style={{
        backgroundColor: `var(--${bgColor})`,
        color: `var(--${fontColor})`,
        ...style,
      }}
      className={`ui-button ${variant} ${className ?? ''}`}
      disabled={disabled}
      onClick={onClick}>
      <span
        style={{
          borderBottom: variant === 'link' ? `0.5px solid var(--${fontColor})` : undefined,
        }}>
        {children}
      </span>
      {loading && <LoadingComponent />}
    </button>
  );
};

export default Button;
