import { InputLabel } from '@mui/material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { NormalAlert } from '../../../../../components/core/alert-card/alert-cards';
import CorpCodeSelector from '../../../../../components/core/multi-select-checkbox-corpcode-input';
import Button from '../../../../../components/ui/button';
import { Item } from '../../../../../components/ui/dropdownBtn';
import MultiSelectDropdownBtn from '../../../../../components/ui/multi-select-dropdown-btn';
import Typography from '../../../../../components/ui/typography';
import useMacroGraph from '../../../../../resources/macro-graph/macro-graph-hook';
import useNotes from '../../../../../resources/notes/notes-hook';
import {
  allTypesOptions,
  cleanDefaultNotesFilters,
  getFiltersFromStorage,
  saveFiltersToStorage,
} from '../../../../../resources/notes/utils';
import './index.scss';

interface Props {
  setInitialFetch: () => void;
}

const Filter: React.FC<Props> = ({ setInitialFetch }) => {
  const itemSavedInLocal = getFiltersFromStorage();

  const { chartStyle } = useMacroGraph();
  const { filterNotes, filterFields, setFilterFields } = useNotes();

  const allLinesCountryCodes = useMemo(() => {
    return Array.from(new Set(chartStyle.lineConfigurations.flatMap(lineRequest => lineRequest.countryCodes)));
  }, [chartStyle.lineConfigurations]);

  const [typeSelected, setTypeSelected] = useState<Item[]>(itemSavedInLocal.typeSelected);
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>(
    allLinesCountryCodes.length > 0 ? allLinesCountryCodes : itemSavedInLocal.filters.countryCodes,
  );

  const [clearCorpCodeSelector, setClearCorpCodeSelector] = useState(false);

  const handleFilterClick = () => {
    if (filterFields.startDate > filterFields.endDate) {
      NormalAlert('Invalid start and end date');
      return;
    }

    let filtersToApply = filterFields;

    saveFiltersToStorage(filtersToApply, typeSelected, corpCodesSelected);
    setInitialFetch();
    filterNotes({ filters: filtersToApply });
  };

  const handleResetFiltersClick = () => {
    setTypeSelected([]);
    setFilterFields(cleanDefaultNotesFilters);
    filterNotes({ filters: cleanDefaultNotesFilters });
    saveFiltersToStorage(cleanDefaultNotesFilters, [], []);
    setClearCorpCodeSelector(!clearCorpCodeSelector);
  };

  useEffect(() => {
    setFilterFields({ ...filterFields, countryCodes: corpCodesSelected });
  }, [corpCodesSelected]);

  useEffect(() => {
    if (allLinesCountryCodes.length > 0) {
      setCorpCodesSelected(allLinesCountryCodes);
      if (filterFields.startDate < filterFields.endDate) {
        filterNotes({ filters: { ...filterFields, countryCodes: allLinesCountryCodes } });
      }
    }
  }, [allLinesCountryCodes]);

  return (
    <div className="note-filter-options">
      <MultiSelectDropdownBtn
        selected={typeSelected}
        setSelected={setTypeSelected}
        id="multi-select-dropdown-types"
        channel={true}
        none
        label=""
        styleType="filter"
        placeholder="Select note types"
        items={allTypesOptions}
        onClick={items => {
          setFilterFields({ ...filterFields, types: items ? items.map(item => String(item.id)) : [] });
        }}
      />
      <div className="data-type-inputs">
        <div className="date-content">
          <InputLabel variant="standard" htmlFor="start-date">
            Start Date
          </InputLabel>
          <input
            id="start-date"
            type="date"
            value={moment(filterFields.startDate).format('YYYY-MM-DD')}
            onChange={date =>
              setFilterFields({ ...filterFields, startDate: moment(date.target.value, 'YYYY-MM-DD').toDate() })
            }
          />
        </div>
        <div className="date-content">
          <InputLabel variant="standard" htmlFor="end-date">
            End Date
          </InputLabel>
          <input
            id="end-date"
            type="date"
            value={moment(filterFields.endDate).format('YYYY-MM-DD')}
            onChange={date =>
              setFilterFields({ ...filterFields, endDate: moment(date.target.value, 'YYYY-MM-DD').toDate() })
            }
          />
        </div>
      </div>
      <div>
        <Typography fontSize="10px" color="detail-gray">
          Corp Codes
        </Typography>
        <CorpCodeSelector
          selectedCorpCodes={corpCodesSelected}
          setCorpCodesSelected={setCorpCodesSelected}
          clear={clearCorpCodeSelector}
        />
      </div>
      <div className="data-type-inputs">
        <div className="filter-buttons">
          <Button onClick={handleResetFiltersClick}>Reset</Button>
          <Button onClick={handleFilterClick}>Filter</Button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
