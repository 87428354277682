import { useEffect, useState } from 'react';
import { IoFilterSharp } from 'react-icons/io5';
import ExternalDataRenderer from '../../../../components/hocs/external-data-renderer';
import Button from '../../../../components/ui/button';
import SideWrapper from '../../../../components/ui/side-wrapper';
import useCommon from '../../../../resources/common/common-hook';
import useNotes from '../../../../resources/notes/notes-hook';
import { Note } from '../../../../resources/notes/notes-types';
import { defaultNote, getFiltersFromStorage } from '../../../../resources/notes/utils';
import AddNoteTab from './add-note-tab';
import EditNoteTab from './edit-note-tab';
import Filter from './filter';
import './index.scss';
import NoteCard from './note-card';

interface Props {
  isToShow: boolean;
  close: () => void;
}

const NotesTab: React.FC<Props> = ({ isToShow, close }) => {
  const [isToShowAddNoteTab, setIsToShowAddNoteTab] = useState(false);
  const [isToShowEditNoteTab, setIsToShowEditNoteTab] = useState(false);

  const [noteToBeEdited, setNoteToBeEdited] = useState(defaultNote);

  const [showFilters, setShowFilters] = useState(true);
  const [initialFetch, setInitialFetch] = useState(false);

  const { filterNotes, filteredNotes } = useNotes();
  const { fetchAllCountries } = useCommon();

  const defaultNoteFilters = getFiltersFromStorage().filters;

  const handleNoteClicked = (note: Note) => {
    setNoteToBeEdited(note);
    setIsToShowEditNoteTab(true);
  };

  useEffect(() => {
    if (!initialFetch) {
      fetchAllCountries();
      filterNotes({ filters: defaultNoteFilters });
      setInitialFetch(true);
    }
  }, []);

  return (
    <>
      <SideWrapper title="Notes" isToShow={isToShow} close={close}>
        <button className="show-filter-button" onClick={() => setShowFilters(!showFilters)}>
          <IoFilterSharp size={28} />
        </button>
        <div className="note-tab-body">
          {showFilters ? <Filter setInitialFetch={() => setInitialFetch(true)} /> : <></>}
          {initialFetch ? (
            <ExternalDataRenderer
              externalData={filteredNotes}
              makeDataElement={notes => (
                <div className="all-notes">
                  {notes.length > 0 ? (
                    notes.map(note => <NoteCard key={note.id} note={note} handleNoteClicked={handleNoteClicked} />)
                  ) : (
                    <p style={{ textAlign: 'center' }}>No notes found</p>
                  )}
                </div>
              )}
            />
          ) : (
            <></>
          )}
          <Button
            className="add-note-button"
            onClick={() => {
              setIsToShowAddNoteTab(true);
            }}>
            Add note
          </Button>
        </div>
        <AddNoteTab isToShow={isToShowAddNoteTab} close={() => setIsToShowAddNoteTab(false)} />
        <EditNoteTab isToShow={isToShowEditNoteTab} close={() => setIsToShowEditNoteTab(false)} note={noteToBeEdited} />
      </SideWrapper>
    </>
  );
};

export default NotesTab;
