import React from 'react';
import { Container, Image, Navbar } from 'react-bootstrap';
import { Environments } from '../../../../@types/configuration';
import { User } from '../../../../resources/auth/user-model';
import './Header.scss';
import QaHeaderContainer from './qa-header-container';
import UserDetailsComponent from './user-details';
import UserSignedOutComponent from './user-signed-out';

export interface HeaderProps {
  title: string;
  brandHref?: string;
  appVersion?: string;
  shouldShowQaContainer: boolean;
  environment?: Environments;
  isAuthenticated: boolean;
  user?: User;
  onSettingsClick?: VoidFunction;
}

const Header: React.FC<HeaderProps> = ({
  title,
  brandHref = '/dashboard',
  appVersion,
  environment,
  shouldShowQaContainer,
  isAuthenticated,
  user,
  onSettingsClick,
}) => {
  return (
    <Navbar sticky="top">
      <Container fluid>
        {shouldShowQaContainer && <QaHeaderContainer environment={environment} onSettingsClick={onSettingsClick} />}
        <Navbar.Brand href={brandHref} className="navbar-content">
          <Image src="../../../assets/amway-logo.svg" width="98" height="35" />
          <div className="app-info">
            <Navbar.Text className="app-name">{title}</Navbar.Text>
            {appVersion && <span className="app-version">{appVersion}</span>}
          </div>
        </Navbar.Brand>
        <div className="user-container">
          {isAuthenticated ? <UserDetailsComponent user={user!} /> : <UserSignedOutComponent />}
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
