import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { CiSearch } from 'react-icons/ci';
import useCommon from '../../../resources/common/common-hook';
import { MultiSelectCheckboxOption } from '../../../resources/common/common-types';
import useFeatures from '../../../resources/features/features-hook';
import Button from '../../ui/button';
import { Item } from '../../ui/dropdownBtn';
import {
  findOptionByValue,
  getNewCheckedItems,
  getNewCheckedItemsCorpCode,
  getSelectedValues,
  sortBySortedOptionSelected,
} from '../utils';
import './index.scss';
import SortOptionModal from './sort-options-modal/short-options-modal';

interface Props {
  selectedCorpCodes: string[];
  setCorpCodesSelected: React.Dispatch<React.SetStateAction<string[]>>;
  clear?: boolean;
  setTextToShow?: (s: string) => void;
  multi?: boolean;
}

export default function CorpCodeSelector({
  setCorpCodesSelected,
  selectedCorpCodes,
  setTextToShow,
  clear = false,
  multi = true,
}: Props) {
  const { countriesTree, fetchAllCountriesTree } = useCommon();
  const { countries: appliedCountries } = useFeatures();

  const [showModal, setShowModal] = useState(false);
  const [expandedParents, setExpandedParents] = useState<Record<string, boolean>>({});

  const [showSortModal, setShowSortModal] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState<Item | undefined>(undefined);

  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>(
    Object.assign(
      {},
      ...selectedCorpCodes.map(corpCode => {
        return { [corpCode]: true };
      }),
    ),
  );

  const [textToCorpCodesSelected, setTextToCorpCodesSelected] = useState('');

  const getOptionsToShow = useCallback(() => {
    if (selectedSortOption) {
      return sortBySortedOptionSelected(selectedSortOption, countriesTree);
    }

    return countriesTree;
  }, [selectedSortOption, countriesTree]);

  const optionsToShow = getOptionsToShow();

  const toggleParent = (value: string) => {
    setExpandedParents({ ...expandedParents, [value]: !expandedParents[value] });
  };

  const expandeAll = () => {
    let allExpanded: Record<string, boolean> = {};

    const expand = (option: MultiSelectCheckboxOption) => {
      allExpanded[option.value] = true;
      if (option.children.length > 0) {
        option.children.forEach(child => {
          expand(child);
        });
      }
    };

    optionsToShow.forEach(option => {
      expand(option);
    });

    setExpandedParents(allExpanded);
  };

  const handleClearButton = () => {
    setCheckedItems({});
    setTextToCorpCodesSelected('');
  };

  useEffect(() => {
    handleClearButton();
  }, [clear]);

  useEffect(() => {
    fetchAllCountriesTree();
  }, [appliedCountries]);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: MultiSelectCheckboxOption) => {
      const { checked } = event.target;

      let newCheckedItems;
      if (multi) {
        newCheckedItems = getNewCheckedItems(checked, item, optionsToShow, checkedItems);
      } else {
        newCheckedItems = checked ? { [item.value]: true } : {};
      }
      setCheckedItems(newCheckedItems);
    },
    [checkedItems, multi, optionsToShow],
  );

  const updateCorpCodeSelectorText = useCallback(
    (checkedItems: Record<string, boolean>) => {
      const [selectedValues, textToShow] = getSelectedValues(checkedItems, optionsToShow);
      const text = textToShow.join('|');
      setTextToCorpCodesSelected(text);
      if (setTextToShow) setTextToShow(text);
      return selectedValues;
    },
    [optionsToShow, setTextToShow],
  );

  const saveCheckedOptions = useCallback(() => {
    const selectedValues = updateCorpCodeSelectorText(checkedItems);
    setCorpCodesSelected(selectedValues);
    setShowModal(false);
  }, [checkedItems, setCorpCodesSelected, updateCorpCodeSelectorText]);

  useEffect(() => {
    if (textToCorpCodesSelected === '' && selectedCorpCodes.length > 0) {
      const newCheckedItems = multi
        ? getNewCheckedItemsCorpCode(
            optionsToShow,
            selectedCorpCodes,
            selectedCorpCodes.reduce((acc, corpCode) => ({ ...acc, [corpCode]: true }), {}),
          )
        : { [selectedCorpCodes[0]]: true };

      updateCorpCodeSelectorText(newCheckedItems);
      setCheckedItems(newCheckedItems);
    }
  }, [selectedCorpCodes]);

  const renderCheckbox = useCallback(
    (item: MultiSelectCheckboxOption) => {
      const isChecked = checkedItems[item.value] || false;
      const isParentExpanded = expandedParents[item.value] || false;

      let hasCountryCode = item.value !== item.label;
      let hasLaunchDate = item.launchDate !== null;
      let label = item.label;
      label = hasCountryCode ? item.value + ' - ' + label : label;
      label = hasLaunchDate ? label + ' ...... ' + moment(item.launchDate).format('MM/YYYY') : label;

      return (
        <div key={item.value} className="multi-select-checkbox">
          {item.children.length > 0 && (
            <span
              className={`parent-icon ${isParentExpanded ? 'expanded' : 'collapsed'}`}
              onClick={() => toggleParent(item.value)}>
              {isParentExpanded ? '-' : '+'}
            </span>
          )}
          {multi || item.children.length === 0 ? (
            <Form.Check
              type={multi ? 'checkbox' : 'radio'}
              id={item.value}
              label={label}
              checked={isChecked}
              onChange={event => handleCheckboxChange(event, item)}
            />
          ) : (
            <>{label}</>
          )}

          <div className={`child-options ${isParentExpanded ? 'parent-expanded' : ''}`}>
            {item.children.length > 0 && item.children.map(child => renderCheckbox(child))}
          </div>
        </div>
      );
    },
    [checkedItems, expandedParents, multi, toggleParent, handleCheckboxChange],
  );

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const onChangeTextString = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim().replace(/[^0-9|;,]/g, '');
      const normalizedString = multi ? value.replace(/[\|;,]/g, '|') : value.slice(0, 3);
      const list = normalizedString
        .split('|')
        .map(s => s.trim())
        .filter(s => s.length > 0);

      const allElements = multi ? list : list.length > 0 ? [list[0]] : [];

      let newCheckedItems = {};
      allElements.forEach(value => {
        const selectCheckBox = findOptionByValue(optionsToShow, value);

        if (selectCheckBox !== undefined) {
          newCheckedItems = getNewCheckedItems(true, selectCheckBox, optionsToShow, newCheckedItems);
        }
      });
      setCheckedItems(newCheckedItems);

      setTextToCorpCodesSelected(multi ? value : value.slice(0, 3));

      if (setTextToShow) {
        setTextToShow(multi ? value : value.slice(0, 3));
      }
      setCorpCodesSelected(allElements);
    },
    [multi, optionsToShow, setCorpCodesSelected, setTextToShow],
  );

  useEffect(() => {
    expandeAll();
  }, [countriesTree]);

  return (
    <Form.Group>
      <div className="input-selected-checkbox-values">
        <Form.Control
          autoFocus
          className="container"
          onChange={onChangeTextString}
          onClick={handleInputClick}
          value={textToCorpCodesSelected}
        />
        <button onClick={() => setShowModal(true)}>
          <CiSearch />
        </button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="multi-level-select-checkbox-modal">
        <Modal.Header>
          <Button onClick={() => setExpandedParents({})}>COLLAPSE ALL</Button>
          <Button onClick={expandeAll}>EXPAND ALL</Button>
          <Button onClick={() => setShowSortModal(true)}>SORT</Button>
          <Button onClick={handleClearButton}>CLEAR</Button>
          <SortOptionModal
            showSortModal={showSortModal}
            setShowSortModal={setShowSortModal}
            selectedSortOption={selectedSortOption}
            setSelectedSortOption={setSelectedSortOption}
          />
        </Modal.Header>
        <Modal.Body>{optionsToShow.map(option => renderCheckbox(option))}</Modal.Body>
        <Modal.Footer>
          <Button onClick={saveCheckedOptions}>SAVE</Button>
        </Modal.Footer>
      </Modal>
    </Form.Group>
  );
}
