import DialogLayout from '../../../../../../components/core/dialog-layout';
import Typography from '../../../../../../components/ui/typography';
import { exchangeRatesData } from './data';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function CurrencyInfoModal({ isOpen, setIsOpen }: Props) {
  return (
    <DialogLayout
      open={isOpen}
      title="Currency Info"
      scapeActionText="Close"
      scapeActionHandler={() => {
        setIsOpen(false);
      }}>
      <ul
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}>
        {exchangeRatesData.map((item, index) => (
          <li
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}>
            <Typography variant="body" color="text-black" weight="bold">
              {item.title}
            </Typography>
            <Typography variant="body" color="text-black">
              {item.description}
            </Typography>
          </li>
        ))}
      </ul>
    </DialogLayout>
  );
}
