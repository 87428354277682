import { useMemo } from 'react';
import useCommon from '../../../../resources/common/common-hook';
import useMacroGraph from '../../../../resources/macro-graph/macro-graph-hook';
import { Line } from '../../../../resources/macro-graph/macro-graph-types';
import { FormattedDataType, getInitialFormData } from '../../editable-yrMo-table/utils';
import './index.scss';

interface ComponentToPdfProps {
  lines: Line[];
  color: string;
  lineIndex: number;
}

export default function TableToPdf({ lines, color, lineIndex }: ComponentToPdfProps) {
  const { chartStyle } = useMacroGraph();
  const { dataTypesMetadata } = useCommon();

  const isFiscal = useMemo(() => chartStyle.calendars === 'fiscal', [chartStyle.calendars]);

  const formatedData = useMemo<FormattedDataType>(() => {
    const formatedData = getInitialFormData(lines, lineIndex, chartStyle, isFiscal, dataTypesMetadata);
    return formatedData;
  }, [lines, lineIndex, chartStyle, isFiscal, dataTypesMetadata]);

  return (
    <>
      {chartStyle.graphOptions.scaleResults ? <div>All values from table and graph are scaled (000)</div> : <></>}
      {formatedData !== null && formatedData !== undefined && formatedData.length > 0 ? (
        <table className="table-to-pdf">
          <thead>
            <tr>
              <th>Year</th>
              <th>Jan</th>
              <th>Feb</th>
              <th>Mar</th>
              <th>Apr</th>
              <th>May</th>
              <th>Jun</th>
              <th>Jul</th>
              <th>Aug</th>
              <th>Sep</th>
              <th>Oct</th>
              <th>Nov</th>
              <th>Dec</th>
              <th>Total</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr className="title" style={{ backgroundColor: color }}>
              <td colSpan={15}>{formatedData?.[lineIndex].title}</td>
            </tr>
            {formatedData?.[lineIndex].rows.map((row, index) => {
              if (index > 0)
                return (
                  <tr key={index}>
                    <td>{row.year}</td>
                    <td>{row.jan}</td>
                    <td>{row.feb}</td>
                    <td>{row.mar}</td>
                    <td>{row.apr}</td>
                    <td>{row.may}</td>
                    <td>{row.jun}</td>
                    <td>{row.jul}</td>
                    <td>{row.aug}</td>
                    <td>{row.sep}</td>
                    <td>{row.oct}</td>
                    <td>{row.nov}</td>
                    <td>{row.dec}</td>
                    <td>{row.total}</td>
                    <td>{row.percentage}</td>
                  </tr>
                );
            })}
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </>
  );
}
