import { DeleteOutline, InfoRounded } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Accordion, Col, FormGroup, Row } from 'react-bootstrap';
import { unstable_batchedUpdates } from 'react-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DataTypeSelector from '../../../../../components/core/data-type-selector';
import MultiSelectCheckboxBusinessLines from '../../../../../components/core/multi-select-checkbox-business-lines-input';
import MultiSelectCheckboxCategories from '../../../../../components/core/multi-select-checkbox-categories';
import CorpCodeSelector from '../../../../../components/core/multi-select-checkbox-corpcode-input';
import CurrencyFilterInput from '../../../../../components/ui/currency-filter-option';
import DropdownBtn, { Item } from '../../../../../components/ui/dropdownBtn';
import FloatingMenu from '../../../../../components/ui/floating-menu';
import Typography from '../../../../../components/ui/typography';
import useCommon from '../../../../../resources/common/common-hook';
import { DataTypeOption } from '../../../../../resources/common/common-types';
import { ChartTypes, MacroGraphChartLineRequest } from '../../../../../resources/macro-graph/macro-graph-types';
import {
  currencyOptions,
  findDataType,
  graphAsOptions,
  monthOptions,
  rollingMonthOptions,
  rollingTypeOptions,
  scaleOptions,
} from '../../../utils';
import AffiliatesInfoModal from './info-modals/affiliates-info';
import DataTypesInfoModal from './info-modals/data-types-info';
import PercentageChangeInfoModal from './info-modals/percentage-change-info';
import Show12MonthRollingAverageInfoModal from './info-modals/show-months-rolling-average-info';

interface Props {
  onDeleteLine: (id: number) => void;
  line: MacroGraphChartLineRequest;
  setLineCharts: React.Dispatch<React.SetStateAction<MacroGraphChartLineRequest[]>>;
  index: number;
  setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  addBusinessLines: (addOnly: boolean) => void;
}

export default function LineAccordionItem({
  onDeleteLine,
  line,
  setLineCharts,
  index,
  setActiveKey,
  addBusinessLines,
}: Props) {
  const { dataTypes, fcastSources, exchangeRateYearOptions, dataTypesMetadata, yearOptions } = useCommon();

  const [floatingMenuAnchorEl, setFloatingMenuAnchorEl] = useState<null | Element>(null);

  const [regionSelected, setRegionSelected] = useState(line.countriesCodes ? line.countriesCodes.join('|') : '');
  const [disableLocal, setDisableLocal] = useState(false);
  const [disableCurrency, setDisableCurrency] = useState(false);
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>(line.countriesCodes ? line.countriesCodes : []);
  const [businessLinesSelected, setBusinessLinesSelected] = useState<string[]>(
    line.businessLine ? line.businessLine : [],
  );
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>(line.category ? line.category : []);

  const [dataTypesInfoModalOpen, setDataTypesInfoModalOpen] = useState(false);
  const [percentageChangeInfoModalOpen, setPercentageChangeInfoModalOpen] = useState(false);
  const [showRollingAverageInfoModalOpen, setShowRollingAverageInfoModalOpen] = useState(false);
  const [affiliatesInfoModalOpen, setAffiliatesInfoModalOpen] = useState(false);

  const [fcastSourceSelected, setFcastSourceSelected] = useState<Item | undefined>(
    line.fcastSource
      ? fcastSources.find(item => item.id === line.fcastSource)
      : { id: 'Current Month', label: 'Current Month' },
  );

  const [scaleSelected, setScaleSelected] = useState<Item | undefined>(
    line.scale ? scaleOptions.find(item => item.id === line.scale) : scaleOptions[0],
  );

  const [currencySelected, setCurrencySelected] = useState<Item | undefined>(
    line.currencyOptions.type
      ? currencyOptions.find(item => item.id === line.currencyOptions.type)
      : currencyOptions[5],
  );
  const [currencyMonthSelected, setCurrencyMonthSelected] = useState<Item | undefined>(
    line.currencyOptions.month ? monthOptions.find(item => item.id === line.currencyOptions.month) : undefined,
  );
  const [currencyYearSelected, setCurrencyYearSelected] = useState<Item | undefined>(
    line.currencyOptions.year && exchangeRateYearOptions.length > 0
      ? exchangeRateYearOptions.find(item => item.id === line.currencyOptions.year)
      : { label: moment().year().toString(), id: moment().year() },
  );
  const [graphAsSelected, setGraphAsSelected] = useState<Item | undefined>(
    line.graphAs ? graphAsOptions.find(item => item.id === line.graphAs) : graphAsOptions[0],
  );
  const [dataTypeSelected, setDataTypeSelected] = useState<DataTypeOption | null>(
    line.dataType ? findDataType(dataTypes, line.dataType) : null,
  );
  const [rollingMonthSelected, setRollingMonthSelected] = useState<Item | undefined>(
    line.rollingMonth ? rollingMonthOptions.find(item => item.id === line.rollingMonth) : rollingMonthOptions[0],
  );
  const [rollingTypeSelected, setRollingTypeSelected] = useState<Item | undefined>(
    line.rollingType ? rollingTypeOptions.find(item => item.id === line.rollingType) : rollingTypeOptions[2],
  );

  const [startMonthSelected, setStartMonthSelected] = useState<Item | undefined>(
    line.dateInterval.startMonth
      ? monthOptions.find(item => item.id === line.dateInterval.startMonth)
      : monthOptions[0],
  );

  const [startYearSelected, setStartYearSelected] = useState<Item | undefined>(
    line.dateInterval.startYear ? yearOptions.find(item => item.id === line.dateInterval.startYear) : yearOptions[0],
  );

  const [endYearSelected, setEndYearSelected] = useState<Item | undefined>(
    line.dateInterval.endYear
      ? yearOptions.find(item => item.id === line.dateInterval.endYear)
      : yearOptions[yearOptions.length - 1],
  );

  const [endMonthSelected, setEndMonthSelected] = useState<Item | undefined>(
    line.dateInterval.endMonth
      ? monthOptions.find(item => item.id === line.dateInterval.endMonth)
      : monthOptions[monthOptions.length - 1],
  );

  // sync lineCharts with initial line data
  useEffect(() => {
    setLineCharts(prev =>
      prev.map(l => {
        if (l.id === line.id) {
          return {
            ...l,
            dataType: dataTypeSelected?.value?.toString() ?? line.dataType,
            fcastSource: fcastSourceSelected?.id?.toString() ?? line.fcastSource,
            countriesCodes: line.countriesCodes,
            businessLine: line.businessLine,
            category: line.category,
            changeToPercentage: line.changeToPercentage,
            rollingType: rollingTypeSelected?.id?.toString() ?? line.rollingType,
            rollingMonth: Number(rollingMonthSelected?.id ?? line.rollingMonth),
            scale: scaleSelected?.id?.toString() ?? line.scale,
            show12MonthRollingAvg: line.show12MonthRollingAvg,
            graphAs: (graphAsSelected?.id?.toString() as ChartTypes) ?? line.graphAs,
            currencyOptions: {
              year: line.currencyOptions.year,
              month: line.currencyOptions.month,
              type: line.currencyOptions.type,
            },
            dateInterval: {
              startYear: Number(startYearSelected?.id ?? line.dateInterval.startYear),
              endYear: Number(endYearSelected?.id ?? line.dateInterval.endYear),
              startMonth: Number(startMonthSelected?.id ?? line.dateInterval.startMonth),
              endMonth: Number(endMonthSelected?.id ?? line.dateInterval.endMonth),
            },
          };
        }
        return l;
      }),
    );
  }, []);

  const setField = (field: string, value: any) => {
    if (!line.id) return;

    if (field.slice(0, 8) === 'currency') {
      const currencyField = field.slice(8, 9).toLowerCase() + field.slice(9);
      setLineCharts(prev =>
        prev.map(l => {
          if (l.id === line.id) {
            return { ...l, currencyOptions: { ...l.currencyOptions, [currencyField]: value } };
          }
          return l;
        }),
      );
      return;
    }

    if (field.slice(0, 12) === 'dateInterval') {
      const dateIntervalField = field.slice(12, 13).toLowerCase() + field.slice(13);
      console.log(dateIntervalField);
      console.log(value);
      console.log({ ...line, dateInterval: { ...line.dateInterval, [dateIntervalField]: value } });

      setLineCharts(prev =>
        prev.map(l => {
          return { ...l, dateInterval: { ...l.dateInterval, [dateIntervalField]: value } };
        }),
      );
      return;
    }

    setLineCharts(prev =>
      prev.map(l => {
        if (l.id === line.id) {
          return { ...l, [field]: value };
        }
        return l;
      }),
    );
  };

  const mountedComponent = useRef(false);
  const handleClearBusinessLinesRef = useRef<VoidFunction>();
  const handleClearCategoriesRef = useRef<VoidFunction>();
  useEffect(() => {
    if (!mountedComponent.current) {
      mountedComponent.current = true;
      return;
    }
    handleClearBusinessLinesRef.current?.();
    handleClearCategoriesRef.current?.();
  }, [dataTypeSelected]);

  useEffect(() => {
    if (dataTypeSelected) {
      const isRelatedToCurrency = dataTypesMetadata.filter(
        dt => dt.dataType === dataTypeSelected?.value && dt.isMonetary,
      );

      if (isRelatedToCurrency.length > 0) {
        setDisableCurrency(false);
        if (corpCodesSelected.length > 1) {
          if (currencySelected === currencyOptions[1]) {
            unstable_batchedUpdates(() => {
              setCurrencySelected(currencyOptions[5]);
              setField('currencyType', currencyOptions[5].id);
            });
          }
          setDisableLocal(true);
        } else {
          setDisableLocal(false);
        }
      } else {
        unstable_batchedUpdates(() => {
          setDisableCurrency(true);
          setDisableLocal(false);
          setCurrencySelected(currencyOptions[1]);
          setField('currencyType', currencyOptions[1].id);
        });
      }
    } else {
      unstable_batchedUpdates(() => {
        setDisableCurrency(false);
        setDisableLocal(false);
      });
    }
  }, [corpCodesSelected, dataTypeSelected]);

  useEffect(() => {
    setField('countriesCodes', corpCodesSelected);

    if (
      dataTypeSelected !== null &&
      dataTypesMetadata.find(metadata => metadata.dataType === dataTypeSelected.value && metadata.hasBusinessLine)
    ) {
      unstable_batchedUpdates(() => {
        setField('businessLine', businessLinesSelected);
        setField('category', categoriesSelected);
      });
    } else {
      unstable_batchedUpdates(() => {
        setField('businessLine', []);
        setField('category', []);
      });
    }
  }, [businessLinesSelected, categoriesSelected, corpCodesSelected, dataTypeSelected]);

  useEffect(() => {
    if (businessLinesSelected.length > 0 || categoriesSelected.length > 0) {
      unstable_batchedUpdates(() => {
        setFcastSourceSelected({ id: 'Current Month', label: 'Current Month' });
        setField('fcastSource', 'Current Month');
      });
    }
  }, [businessLinesSelected.length, categoriesSelected.length]);

  return (
    <Accordion.Item eventKey={(line?.id ?? 0).toString()}>
      <Accordion.Header
        onClick={() => setActiveKey(prev => (prev !== line?.id?.toString() ? line?.id?.toString() : undefined))}>
        <Row style={{ width: '100%' }}>
          <Col>
            <Row>
              <Typography weight="bold" fontSize="12px">
                Line {index + 1}
              </Typography>
            </Row>
            <Row className="mt-2">
              <Typography color="detail-gray" fontSize="10px">
                {regionSelected.length > 18 ? regionSelected.substring(0, 18) + '...' : regionSelected}{' '}
                {dataTypesMetadata.find(metaData => metaData.dataType === line.dataType)
                  ? `- ${
                      dataTypesMetadata.find(metaData => metaData.dataType === line.dataType)?.frontendPresentableName
                    } - `
                  : line.dataType}{' '}
                {line.fcastSource ? `${line.fcastSource}` : ''}
                {line.businessLine && line.businessLine?.length > 0 ? `- ${line.businessLine.join(', ')}` : ''}
                {line.category && line.category?.length > 0 ? `- ${line.category.join(', ')}` : ''}
              </Typography>
            </Row>
          </Col>
          {index === 0 && (
            <button
              onClick={e => {
                e.stopPropagation();
                setFloatingMenuAnchorEl(e.currentTarget);
              }}
              style={{
                background: 'none',
                border: 'none',
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                position: 'relative',
              }}>
              <BsThreeDotsVertical />
            </button>
          )}
          <div
            onClick={() => line.id && onDeleteLine(line.id)}
            style={{ width: 'max-content', display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <DeleteOutline />
          </div>
        </Row>
      </Accordion.Header>
      <Accordion.Body>
        <Typography fontSize="10px" color="detail-gray">
          Fast Data Type
        </Typography>
        <RadioGroup
          onChange={(e, value) => {
            unstable_batchedUpdates(() => {
              setDataTypeSelected(findDataType(dataTypes, value));
              setField('dataType', value);
              setCategoriesSelected([]);
              setBusinessLinesSelected([]);
            });
          }}
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <FormControlLabel
            checked={dataTypeSelected?.value === 'revenue'}
            style={{ marginRight: '14px' }}
            value="revenue"
            control={<Radio />}
            label="Rev"
            title="Revenue"
          />
          <FormControlLabel
            checked={dataTypeSelected?.value === 'dist_cost_total'}
            style={{ marginRight: '14px' }}
            value="dist_cost_total"
            control={<Radio />}
            label="DC"
            title="Dist Cost"
          />
          <FormControlLabel
            checked={dataTypeSelected?.value === 'mat_revenue'}
            style={{ marginRight: '14px' }}
            value="mat_revenue"
            control={<Radio />}
            label="MAT"
            title="MAT Revenue"
          />
          <FormControlLabel
            checked={dataTypeSelected?.value === 'total_application'}
            style={{ marginRight: '14px' }}
            value="total_application"
            control={<Radio />}
            label="APP"
            title="Total Applications"
          />
          <FormControlLabel
            checked={dataTypeSelected?.value === 'monthly_buyers'}
            style={{ marginRight: '0' }}
            value="monthly_buyers"
            control={<Radio />}
            label="Buyers"
            title="Monthly Buyers - Total"
          />
        </RadioGroup>
        <Row className="mt-3">
          <Col>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <Typography fontSize="10px" color="detail-gray">
                  Data Type
                </Typography>

                <div style={{ cursor: 'pointer' }} onClick={() => setDataTypesInfoModalOpen(true)}>
                  <InfoRounded />
                </div>
              </div>

              {dataTypesMetadata.find(metadata => metadata.dataType === dataTypeSelected?.value)?.section ===
                'MODELS' && (
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <Typography fontSize="10px" color="detail-gray">
                    Affiliates
                  </Typography>
                  <div style={{ cursor: 'pointer' }} onClick={() => setAffiliatesInfoModalOpen(true)}>
                    <InfoRounded />
                  </div>
                </div>
              )}
            </div>
            <DataTypeSelector
              value={dataTypeSelected}
              multiple={false}
              items={dataTypes}
              handleSingleChange={item => {
                if (item) {
                  unstable_batchedUpdates(() => {
                    setDataTypeSelected(item);
                    setField('dataType', item.value);
                    setCategoriesSelected([]);
                    setBusinessLinesSelected([]);
                  });
                }
              }}
            />
          </Col>
        </Row>
        {dataTypeSelected !== null &&
          dataTypesMetadata.find(
            metadata => metadata.dataType === dataTypeSelected.value && metadata.hasBusinessLine,
          ) !== undefined && (
            <>
              <Row className="mt-3">
                <Col>
                  <Typography fontSize="10px" color="detail-gray">
                    Business Lines
                  </Typography>
                  <MultiSelectCheckboxBusinessLines
                    selectedBusinessLines={businessLinesSelected}
                    setSelectedBusinessLines={setBusinessLinesSelected}
                    disabled={categoriesSelected.length > 0}
                    handleClearRef={handleClearBusinessLinesRef}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Typography fontSize="10px" color="detail-gray">
                    Categories
                  </Typography>
                  <MultiSelectCheckboxCategories
                    dataTypeSelected={dataTypeSelected.value}
                    selectedCategories={categoriesSelected}
                    setSelectedCategories={setCategoriesSelected}
                    disabled={businessLinesSelected.length > 0}
                    handleClearRef={handleClearCategoriesRef}
                  />
                </Col>
              </Row>
            </>
          )}
        <Row className="mt-3">
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Corp Codes
            </Typography>
            <CorpCodeSelector
              selectedCorpCodes={corpCodesSelected}
              setCorpCodesSelected={setCorpCodesSelected}
              clear={false}
              setTextToShow={setRegionSelected}
            />
          </Col>
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Scale
            </Typography>
            <DropdownBtn
              id="choose-scale-dropdown"
              styleType="filter"
              placeholder="Select"
              items={scaleOptions}
              value={scaleSelected}
              onClick={item => {
                if (item) {
                  unstable_batchedUpdates(() => {
                    setScaleSelected(item);
                    setField('scale', item.id);
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Start Date
            </Typography>
            <Col>
              <Row>
                <Col>
                  <DropdownBtn
                    id="choose-start-month-dropdown"
                    styleType="filter"
                    placeholder=""
                    items={monthOptions}
                    value={monthOptions.find(option => option.id === line.dateInterval.startMonth)}
                    onClick={item => {
                      if (item) {
                        unstable_batchedUpdates(() => {
                          setStartMonthSelected(item);
                          setField('dateIntervalStartMonth', item.id);
                        });
                      }
                    }}
                  />
                </Col>
                <Col>
                  <DropdownBtn
                    id="choose-start-year-dropdown"
                    styleType="filter"
                    placeholder=""
                    items={yearOptions}
                    value={yearOptions.find(option => option.id === line.dateInterval.startYear)}
                    onClick={item => {
                      if (item) {
                        unstable_batchedUpdates(() => {
                          setStartYearSelected(item);
                          setField('dateIntervalStartYear', item.id);
                        });
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Col>
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              End Date
            </Typography>
            <Col>
              <Row>
                <Col>
                  <DropdownBtn
                    id="choose-end-month-dropdown"
                    styleType="filter"
                    placeholder=""
                    items={monthOptions}
                    value={monthOptions.find(option => option.id === line.dateInterval.endMonth)}
                    onClick={item => {
                      if (item) {
                        unstable_batchedUpdates(() => {
                          setEndMonthSelected(item);
                          setField('dateIntervalEndMonth', item.id);
                        });
                      }
                    }}
                  />
                </Col>
                <Col>
                  <DropdownBtn
                    id="choose-end-year-dropdown"
                    styleType="filter"
                    placeholder=""
                    items={yearOptions}
                    value={yearOptions.find(option => option.id === line.dateInterval.endYear)}
                    onClick={item => {
                      if (item) {
                        unstable_batchedUpdates(() => {
                          setEndYearSelected(item);
                          setField('dateIntervalEndYear', item.id);
                        });
                      }
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Fcast Source
            </Typography>
            <DropdownBtn
              id="choose-fcast-source-dropdown"
              styleType="filter"
              placeholder="Select"
              items={fcastSources}
              value={fcastSourceSelected}
              disabled={businessLinesSelected.length > 0 || categoriesSelected.length > 0}
              onClick={item => {
                if (item) {
                  unstable_batchedUpdates(() => {
                    setFcastSourceSelected(item);
                    setField('fcastSource', item.id);
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3"></Row>
        <CurrencyFilterInput
          currencyOptions={currencyOptions}
          currencySelected={currencySelected}
          setCurrencySelected={setCurrencySelected}
          setField={setField}
          disableLocal={disableLocal}
          currencyMonthSelected={currencyMonthSelected}
          setCurrencyMonthSelected={setCurrencyMonthSelected}
          currencyYearSelected={currencyYearSelected}
          setCurrencyYearSelected={setCurrencyYearSelected}
          disable={disableCurrency}
        />
        <Row className="mt-3">
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Graph as
            </Typography>
            <DropdownBtn
              id="choose-graph-as-dropdown"
              styleType="filter"
              placeholder="Select"
              items={graphAsOptions}
              value={graphAsSelected}
              onClick={item => {
                if (item) {
                  unstable_batchedUpdates(() => {
                    setGraphAsSelected(item);
                    setField('graphAs', item.id);
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Rolling Month
            </Typography>
            <DropdownBtn
              id="choose-rolling-month-dropdown"
              styleType="filter"
              placeholder="Select"
              items={rollingMonthOptions}
              value={rollingMonthSelected}
              onClick={item => {
                if (item) {
                  unstable_batchedUpdates(() => {
                    setRollingMonthSelected(item);
                    setField('rollingMonth', item.id);
                  });
                }
              }}
              disabled={rollingTypeSelected && rollingTypeSelected.id === 'Cumulative'}
            />
          </Col>
          <Col>
            <Typography fontSize="10px" color="detail-gray">
              Rolling Type
            </Typography>
            <DropdownBtn
              id="choose-rolling-type-dropdown"
              styleType="filter"
              placeholder="Select"
              items={rollingTypeOptions}
              value={rollingTypeSelected}
              onClick={item => {
                if (item) {
                  unstable_batchedUpdates(() => {
                    setRollingTypeSelected(item);
                    setField('rollingType', item.id);
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox />}
                label={<span style={{ fontSize: '12px' }}>% Change</span>}
                checked={line.changeToPercentage}
                onChange={(e, checked) => setField('changeToPercentage', checked)}
                style={{ marginRight: '4px' }}
              />
              <div style={{ cursor: 'pointer' }} onClick={() => setPercentageChangeInfoModalOpen(true)}>
                <InfoRounded />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox />}
                label={<span style={{ fontSize: '12px' }}>Show 12 Month Rolling Average</span>}
                checked={line.show12MonthRollingAvg}
                onChange={(e, checked) => setField('show12MonthRollingAvg', checked)}
                style={{ marginRight: '4px' }}
              />
              <div style={{ cursor: 'pointer' }} onClick={() => setShowRollingAverageInfoModalOpen(true)}>
                <InfoRounded />
              </div>
            </div>
          </FormGroup>
        </Row>
        <FloatingMenu
          anchorEl={floatingMenuAnchorEl}
          setAnchorEl={setFloatingMenuAnchorEl}
          menuItems={[
            {
              label: 'Add Business Lines',
              action: () => {
                addBusinessLines(false);
                setFloatingMenuAnchorEl(null);
              },
            },
            {
              label: 'Add only Business Lines',
              action: () => {
                addBusinessLines(true);
                setFloatingMenuAnchorEl(null);
              },
            },
          ]}
        />
      </Accordion.Body>

      {/* MODALS */}
      <DataTypesInfoModal isOpen={dataTypesInfoModalOpen} setIsOpen={setDataTypesInfoModalOpen} />
      <PercentageChangeInfoModal isOpen={percentageChangeInfoModalOpen} setIsOpen={setPercentageChangeInfoModalOpen} />
      <Show12MonthRollingAverageInfoModal
        isOpen={showRollingAverageInfoModalOpen}
        setIsOpen={setShowRollingAverageInfoModalOpen}
      />
      <AffiliatesInfoModal isOpen={affiliatesInfoModalOpen} setIsOpen={setAffiliatesInfoModalOpen} />
    </Accordion.Item>
  );
}
