import moment from 'moment';
import { useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import ExternalDataRenderer from '../../components/hocs/external-data-renderer';
import Button from '../../components/ui/button';
import Typography from '../../components/ui/typography';
import useMacroGraph from '../../resources/macro-graph/macro-graph-hook';
import './index.scss';
import ScenarioForecastTable from './scenario-table';

export default function ScenarioForecast() {
  const [initialFetch, setInitialFetch] = useState<boolean>(false);
  const { fetchDefaultScenarios, allDefaultScenarios } = useMacroGraph();
  const [forecastEndYear, setForecastEndYear] = useState<number>(moment().year() + 2);

  const startYear = moment().year() + 1;
  const endYear = new Date().getFullYear() + 10;
  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

  return (
    <Container style={{ maxWidth: '2000px' }}>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Scenario Forecast</Typography>
          </Row>
          <Row className="align-items-end scenario-forecast-filter">
            <div>
              <Typography fontSize="10px" color="detail-gray">
                Forecast End Year
              </Typography>
              <Form.Group controlId="yearSelect" className="year-select">
                <Form.Control
                  as="select"
                  value={forecastEndYear}
                  onChange={e => setForecastEndYear(Number(e.target.value))}>
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <Col>
              <Button
                onClick={() => {
                  setInitialFetch(true);
                  fetchDefaultScenarios(forecastEndYear);
                }}
                loading={allDefaultScenarios?.loading && initialFetch}
                disabled={(allDefaultScenarios?.loading && initialFetch) || !forecastEndYear}>
                Apply
              </Button>
            </Col>
          </Row>
          {initialFetch && (
            <ExternalDataRenderer
              externalData={allDefaultScenarios}
              makeDataElement={data => <ScenarioForecastTable data={data} finalYear={forecastEndYear} />}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
