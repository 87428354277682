import { Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import CorpCodeSelector from '../../components/core/multi-select-checkbox-corpcode-input';
import Button from '../../components/ui/button';
import LogoLoading from '../../components/ui/logo-loading';
import Typography from '../../components/ui/typography';
import useCommon from '../../resources/common/common-hook';
import useDistCostForecastSettings from '../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings-hook';
import { IDistCostForecastSettings } from '../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings-types';
import DistCostForecastSettingsCard from './dist-cost-forecast-settings-card';
import './index.scss';
import { defaultDistCostForecastSettings } from './util';

export default function DistCostForecastSettings() {
  const [initialFetch, setInitialFetch] = useState<boolean | null>(null);

  const { countries, fetchAllCountries } = useCommon();
  const { distCostForecastSettings, fetchDistCostForecastSettings } = useDistCostForecastSettings();
  const [corpCodesSelected, setCorpCodesSelected] = useState<string[]>([]);

  const [settingsChanged, setSettingsChanged] = useState<IDistCostForecastSettings>(defaultDistCostForecastSettings);
  useEffect(() => {
    if (distCostForecastSettings.data) {
      setInitialFetch(true);
      setSettingsChanged(distCostForecastSettings.data);
    }
  }, [distCostForecastSettings]);

  useEffect(() => {
    if (countries.length === 0) fetchAllCountries();
  }, []);

  const handleSearchButton = () => {
    if (corpCodesSelected.length === 0) {
      NormalAlert('Select the country code');
      return;
    }
    setInitialFetch(false);
    fetchDistCostForecastSettings(corpCodesSelected[0]);
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Dist Cost Forecast Generator</Typography>
            <Typography weight="bold" color="text-gray">
              When enable for a given country, the Dist Cost (incl catalog) forecast will be updated everytime Revenue
              is updated in the system
            </Typography>
          </Row>
          <div className="dist-cost-settings-options">
            <div className="filter">
              <div>
                <Typography fontSize="10px" color="detail-gray">
                  Corp Code
                </Typography>
                <div className="corp-code-selector">
                  <CorpCodeSelector
                    selectedCorpCodes={corpCodesSelected}
                    setCorpCodesSelected={setCorpCodesSelected}
                    clear={false}
                    multi={false}
                  />
                </div>
              </div>
              <Col>
                <Button
                  onClick={handleSearchButton}
                  disabled={distCostForecastSettings.loading && initialFetch !== null && initialFetch}
                  loading={distCostForecastSettings.loading && initialFetch !== null && initialFetch}>
                  Search
                </Button>
              </Col>
              {initialFetch && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={settingsChanged.automaticUpdate}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setSettingsChanged(prev => ({ ...prev, automaticUpdate: e.target.checked }));
                      }}
                    />
                  }
                  label="Generate automatic dist cost forecast"
                />
              )}
            </div>
          </div>
          {initialFetch !== null &&
            (initialFetch ? (
              <DistCostForecastSettingsCard settingsChanged={settingsChanged} setSettingsChanged={setSettingsChanged} />
            ) : (
              <LogoLoading />
            ))}
        </Card.Body>
      </Card>
    </Container>
  );
}
