import { InputLabel, TextField } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfirmDeleteAction, NormalAlert } from '../../../../../components/core/alert-card/alert-cards';
import SelectAutoComplete from '../../../../../components/core/select-autocomplete';
import Button from '../../../../../components/ui/button';
import { Item } from '../../../../../components/ui/dropdownBtn';
import useCommon from '../../../../../resources/common/common-hook';
import useMacroGraph from '../../../../../resources/macro-graph/macro-graph-hook';
import useNotes from '../../../../../resources/notes/notes-hook';
import { Note } from '../../../../../resources/notes/notes-types';
import { allTypesOptions } from '../../../../../resources/notes/utils';

interface Props {
  isToAdd: boolean;
  defaultValue: Note;
  close: () => void;
}

const NoteForm: React.FC<Props> = ({ isToAdd, defaultValue, close }) => {
  const { addNewNote, filterNotes, saveNote, deleteNote, filterFields } = useNotes();
  const { countries } = useCommon();
  const { chartStyle } = useMacroGraph();

  const allLinesCountryCodes = useMemo(() => {
    return Array.from(new Set(chartStyle.lineConfigurations.flatMap(lineRequest => lineRequest.countryCodes)));
  }, [chartStyle.lineConfigurations]);

  const getDefaultValueForNote = useCallback(() => {
    if (isToAdd && allLinesCountryCodes.length > 0) {
      return { ...defaultValue, countryCode: allLinesCountryCodes[0] };
    }
    return defaultValue;
  }, [defaultValue, allLinesCountryCodes, isToAdd]);

  const [note, setNote] = useState<Note>(getDefaultValueForNote());

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setDeleteIsLoading] = useState(false);

  useEffect(() => {
    setNote(getDefaultValueForNote());
  }, [defaultValue, allLinesCountryCodes, isToAdd]);

  const resetFilters = () => {
    close();
    filterNotes({ filters: filterFields });
  };

  const handleAddNote = async () => {
    if (note.note.trim() === '') {
      NormalAlert("Note text can't be empty");
      return;
    }
    if (note.countryCode.trim() === '') {
      NormalAlert("Country code can't be empty");
      return;
    }

    setIsLoading(true);
    const result = await addNewNote({ note: note });

    NormalAlert(result.status === 200 ? 'Note added successfully' : 'Error while creating new note');
    setIsLoading(false);
    resetFilters();
  };

  const handleSaveNote = async () => {
    if (note.note.trim() === '') {
      NormalAlert("Note text can't be empty");
      return;
    }

    setIsLoading(true);
    const result = await saveNote({ note: note });

    NormalAlert(result.status === 200 ? 'Note edited successfully' : 'Error while saving the note');
    setIsLoading(false);
    resetFilters();
  };

  const handleDeleteNote = async () => {
    ConfirmDeleteAction('Note', async () => {
      setDeleteIsLoading(true);
      await deleteNote({ note: note });
      setDeleteIsLoading(false);
      resetFilters();
    });
  };

  return (
    <div className="note-tab-form">
      <SelectAutoComplete
        value={countries.find(country => country.id === note.countryCode) || null}
        multiple={false}
        items={countries}
        label="Country"
        placeholder=""
        onSingleChange={item => {
          setNote({ ...note, countryCode: item.id.toString() });
        }}
      />
      <div className="date-content">
        <InputLabel variant="standard" htmlFor="date">
          Date
        </InputLabel>
        <input
          id="date"
          type="date"
          value={moment(note.date).format('YYYY-MM-DD')}
          onChange={date => {
            setNote({ ...note, date: moment(date.target.value, 'YYYY-MM-DD').toDate() });
          }}
        />
      </div>
      <SelectAutoComplete
        value={allTypesOptions.filter(option => note.types.includes(option.id.toString()))}
        multiple={true}
        items={allTypesOptions}
        label="Type"
        placeholder=""
        onMultipleChange={(values: Item[]) => {
          setNote({ ...note, types: values.map(item => item.id.toString()) });
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          label="Note"
          multiline
          InputLabelProps={{
            shrink: true,
          }}
          rows={6}
          value={note.note}
          inputProps={{ maxLength: 500 }}
          onChange={selected => {
            setNote({ ...note, note: selected.target.value });
          }}
        />
        <span className="details" style={{ textAlign: 'right' }}>
          {note.note.length}/500
        </span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          id="standard-basic"
          label="Link"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          value={note.link}
          inputProps={{ maxLength: 100 }}
          onChange={selected => {
            setNote({ ...note, link: selected.target.value });
          }}
        />
        <span className="details" style={{ textAlign: 'right' }}>
          {note.link.length}/100
        </span>
      </div>
      {!isToAdd ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            <b>Last updated date:</b> {note.updateDt != null ? new Date(note.updateDt.toString()).toUTCString() : ''}
          </span>
          <span>
            <b>Update user:</b> {note.updateUser}
          </span>
        </div>
      ) : (
        <></>
      )}
      <div className="all-buttons">
        {isToAdd ? (
          <Button onClick={handleAddNote} loading={isLoading}>
            Submit
          </Button>
        ) : (
          <>
            <Button onClick={handleDeleteNote} loading={isDeleteLoading}>
              Delete
            </Button>
            <Button onClick={handleSaveNote} loading={isLoading}>
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default NoteForm;
