import React, { ReactNode, useCallback, useMemo } from 'react';
import './index.scss';

type ColorTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'text-black'
  | 'text-gray'
  | 'detail-gray'
  | 'detail-border'
  | 'warning-error'
  | 'warning-success'
  | 'warning-orange'
  | 'warning-blue'
  | 'warning-disable'
  | 'bg-white'
  | 'bg-gray'
  | 'bg-yellow'
  | 'bg-red'
  | 'bg-green'
  | 'bg-blue'
  | 'bg-black'
  | 'bg-transparent';

export const typographyVariantsArr = [
  'display',
  'display404',
  'heading',
  'subheading',
  'body',
  'captionMedium',
  'captionSmall',
] as const;

export const typographyWeights = ['regular', 'bold', 'italic', 'bold-italic'] as const;

export const typograpySizes = ['10px', '12px', '16px', '24px', '32px', '48px', '144px'] as const;

type TypographyVariants = typeof typographyVariantsArr[number];

type TypographyWeights = typeof typographyWeights[number];

type TypographySizes = typeof typograpySizes[number];

export interface TypographyProps {
  children: ReactNode;
  variant?: TypographyVariants;
  weight?: TypographyWeights;
  fontSize?: TypographySizes;
  color?: ColorTypes;
  className?: string;
}

const Typography: React.FC<TypographyProps> = ({
  children,
  variant = 'body',
  weight,
  fontSize,
  className,
  color = 'text-black',
}) => {
  const generateClassname = useCallback(
    (standardWeight: TypographyWeights, standardSize: TypographySizes): string => {
      return `filled-typography ${className} ${weight ?? standardWeight} ${fontSize ?? `f${standardSize}`}`;
    },
    [className, fontSize, weight],
  );

  const FilledTypography = useMemo(() => {
    switch (variant) {
      case 'display':
        return (
          <h1 className={generateClassname('bold', '48px')} style={{ color: `var(--${color})` }}>
            {children}
          </h1>
        );
      case 'display404':
        return (
          <h1 className={generateClassname('bold', '144px')} style={{ color: `var(--${color})` }}>
            {children}
          </h1>
        );
      case 'heading':
        return (
          <h1 className={generateClassname('bold', '32px')} style={{ color: `var(--${color})` }}>
            {children}
          </h1>
        );
      case 'subheading':
        return (
          <h2 className={generateClassname('bold', '24px')} style={{ color: `var(--${color})` }}>
            {children}
          </h2>
        );
      case 'captionMedium':
        return (
          <span className={generateClassname('regular', '12px')} style={{ color: `var(--${color})` }}>
            {children}
          </span>
        );
      case 'captionSmall':
        return (
          <span className={generateClassname('regular', '10px')} style={{ color: `var(--${color})` }}>
            {children}
          </span>
        );
      default:
        // body
        return (
          <span className={generateClassname('regular', '16px')} style={{ color: `var(--${color})` }}>
            {children}
          </span>
        );
    }
  }, [children, color, generateClassname, variant]);

  return FilledTypography;
};

export default Typography;
