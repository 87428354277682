import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { CiSearch } from 'react-icons/ci';
import useCommon from '../../../resources/common/common-hook';
import { MultiSelectCheckboxOption } from '../../../resources/common/common-types';
import Button from '../../ui/button';
import { findOptionByValue, getNewCheckedItems, getSelectedValues } from '../utils';
import './index.scss';

interface Props {
  selectedBusinessLines: string[];
  setSelectedBusinessLines: React.Dispatch<React.SetStateAction<string[]>>;
  handleClearRef?: React.MutableRefObject<VoidFunction | undefined>;
  disabled?: boolean;
}

export default function MultiSelectCheckboxBusinessLines({
  setSelectedBusinessLines,
  selectedBusinessLines,
  handleClearRef,
  disabled = false,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [textToBusinessLinesSelected, setTextToBusinessLinesSelected] = useState(selectedBusinessLines.join(' | '));

  const { businessLines } = useCommon();

  const optionsToShow: MultiSelectCheckboxOption[] = useMemo(
    () =>
      businessLines.map(bl => ({
        label: bl.label,
        value: String(bl.id),
        launchDate: '',
        children: [],
        fatherValue: '',
      })),
    [businessLines],
  );

  const saveCheckedOptions = useCallback(() => {
    const [selectedValues, textToShow] = getSelectedValues(checkedItems, optionsToShow);
    setTextToBusinessLinesSelected(textToShow.join('|'));
    setSelectedBusinessLines(selectedValues);
    setShowModal(false);
  }, [checkedItems, optionsToShow, setSelectedBusinessLines]);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: MultiSelectCheckboxOption) => {
      const { checked } = event.target;
      const newCheckedItems = getNewCheckedItems(checked, item, optionsToShow, checkedItems);
      setCheckedItems(newCheckedItems);
    },
    [checkedItems, optionsToShow],
  );

  const handleClearButton = useCallback(() => {
    setCheckedItems({});
    setTextToBusinessLinesSelected('');
  }, []);

  useEffect(() => {
    if (handleClearRef) {
      handleClearRef.current = handleClearButton;
    }
  }, [handleClearButton, handleClearRef]);

  const renderCheckbox = useCallback(
    (item: MultiSelectCheckboxOption) => {
      const isChecked = checkedItems[item.value] || false;

      let label = item.label;

      return (
        <div key={item.value} className="multi-select-checkbox">
          <Form.Check
            type="checkbox"
            id={item.value}
            label={label}
            checked={isChecked}
            onChange={event => handleCheckboxChange(event, item)}
          />
        </div>
      );
    },
    [checkedItems, handleCheckboxChange],
  );

  const handleInputClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  }, []);

  const onChangeTextString = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim();
      const normalizedString = value.replace(/[\|;,]/g, '|');
      const list = normalizedString
        .split('|')
        .map(s => s.trim())
        .filter(s => s.length > 0);

      let newCheckedItems = {};
      list.forEach(value => {
        const selectCheckBox = findOptionByValue(optionsToShow, value);

        if (selectCheckBox !== undefined) {
          newCheckedItems = getNewCheckedItems(true, selectCheckBox, optionsToShow, newCheckedItems);
        }
      });
      setCheckedItems(newCheckedItems);

      setTextToBusinessLinesSelected(value);
      setSelectedBusinessLines(Object.keys(newCheckedItems));
    },
    [optionsToShow, setSelectedBusinessLines],
  );

  return (
    <Form.Group>
      <div className="input-selected-checkbox-values">
        <Form.Control
          autoFocus
          className="container"
          onChange={onChangeTextString}
          onClick={handleInputClick}
          value={textToBusinessLinesSelected}
          disabled={disabled}
        />
        <button onClick={!disabled ? () => setShowModal(true) : () => {}}>
          <CiSearch />
        </button>
      </div>
      <Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="multi-level-select-checkbox-modal">
        <Modal.Header>
          <Button
            onClick={() => {
              if (Object.keys(checkedItems).length < optionsToShow.length) {
                // select all values
                setCheckedItems(optionsToShow.reduce((acc, item) => ({ ...acc, [item.value]: true }), {}));
              } else {
                // unselect all values
                setCheckedItems({});
              }
            }}>
            {Object.keys(checkedItems).length < optionsToShow.length ? 'CHECK ALL' : 'UNCHECK ALL'}
          </Button>
        </Modal.Header>
        <Modal.Body
          style={{
            minWidth: '500px',
            minHeight: '200px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          {optionsToShow.map(option => renderCheckbox(option))}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveCheckedOptions}>SAVE</Button>
        </Modal.Footer>
      </Modal>
    </Form.Group>
  );
}
