import DialogLayout from '../../../../../../components/core/dialog-layout';
import './affiliates-info.scss';
import { affiliateRows, affiliatesHeaders } from './data';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function AffiliatesInfoModal({ isOpen, setIsOpen }: Props) {
  return (
    <DialogLayout
      open={isOpen}
      title="Affiliates"
      scapeActionText="Close"
      scapeActionHandler={() => {
        setIsOpen(false);
      }}>
      <div className="table-container">
        <table className="simple-table">
          <thead>
            <tr>
              {affiliatesHeaders.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {affiliateRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DialogLayout>
  );
}
