import React from 'react';
import { FiSettings } from 'react-icons/fi';
import './index.scss';

interface Props {
  onSettingsClick?: VoidFunction;
  environment?: string;
}

const QaHeaderContainer: React.FC<Props> = ({ onSettingsClick, environment }) => {
  return (
    <section className="qa-header-container">
      <div className="qa-settings-container-slot">
        {onSettingsClick ? (
          <button onClick={onSettingsClick} className="qa-settings-button">
            <FiSettings className="qa-settings" size="17px" color="var(--primary)" />
          </button>
        ) : (
          <div />
        )}
      </div>
      <h2 className="env-band">{environment}</h2>
    </section>
  );
};

export default QaHeaderContainer;
