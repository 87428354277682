import { FormControlLabel, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { Card, Container, Row } from 'react-bootstrap';
import Typography from '../../components/ui/typography';
import useMaintenance from '../../resources/maintenance/maintenance-hook';

export default function MatRevenueLockScreen() {
  const { isMatRevenueLocked, fetchIsMatRevenueLocked, updateMatRevenueLock } = useMaintenance();

  useEffect(() => {
    if (isMatRevenueLocked === null) fetchIsMatRevenueLocked();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const toggle = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsLoading(true);
    await updateMatRevenueLock(checked);
    setIsLoading(false);
  };

  return (
    <Container>
      <Card>
        <Card.Body>
          <Row>
            <Typography variant="heading">Lock MAT Revenue forecast</Typography>
            <Typography weight="bold" color="text-gray">
              Here you can modify whenever MAT Revenue can be edited or not - When the toggle is on, MAT users won’t be
              able to update the forecast
            </Typography>
            {isMatRevenueLocked !== null ? (
              <FormControlLabel
                control={
                  <Switch
                    checked={isMatRevenueLocked}
                    onChange={toggle}
                    disabled={isLoading}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: 'red !important',
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: 'red !important',
                      },
                    }}
                  />
                }
                label={isLoading ? 'Loading...' : isMatRevenueLocked ? 'LOCKED' : 'UNLOCKED'}
                style={{
                  width: '200px',
                  marginTop: '12px',
                }}
              />
            ) : (
              <></>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
}
