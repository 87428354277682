import React, { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconType } from 'react-icons';
import './index.scss';

export const btnStyleTypesArr = ['default', 'link'] as const;
type BtnStyleTypes = typeof btnStyleTypesArr[number];

export interface IconButtonProps {
  id?: string;
  IconComponent?: IconType;
  onClick?: VoidFunction;
  styleType?: BtnStyleTypes;
  disabled?: boolean;
  color?: string;
  rotate?: number;
  spin?: boolean;
  title?: string;
  tooltip?: string;
  size?: string;
  children?: JSX.Element | ReactNode | null;
  href?: string;
  className?: string;
}

const IconButton: React.FC<IconButtonProps> = ({
  id,
  IconComponent,
  onClick,
  styleType = 'default',
  disabled = false,
  children,
  color = 'black',
  rotate = 180,
  spin = false,
  title,
  tooltip,
  size,
  href,
  className = '',
}) => {
  const isAnchor = useMemo<boolean>(() => !!href && styleType === 'link', [href, styleType]);

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (onClick) {
        e.stopPropagation();
        onClick();
      }
    },
    [onClick],
  );

  const btn = useMemo(
    () => (
      <Button
        id={id}
        as={isAnchor ? 'a' : 'button'}
        className={`icon-btn ${styleType} ${className} ${children ? 'has-children' : ''} ${spin ? 'spinning' : ''}`}
        onClick={onClick && handleClick}
        disabled={disabled}
        href={isAnchor ? href : undefined}
        target="_blank"
        rel="noopener">
        {IconComponent && <IconComponent title={title} size={size} rotate={rotate} color={color} />}
        {children}
      </Button>
    ),
    [
      id,
      isAnchor,
      styleType,
      className,
      children,
      spin,
      onClick,
      handleClick,
      disabled,
      href,
      IconComponent,
      title,
      size,
      rotate,
      color,
    ],
  );

  return !children && tooltip ? (
    <OverlayTrigger
      placement="right"
      overlay={
        <Tooltip>
          <>{tooltip}</>
        </Tooltip>
      }>
      {btn}
    </OverlayTrigger>
  ) : (
    btn
  );
};

export default IconButton;
