import { InfoRounded } from '@mui/icons-material';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import useCommon from '../../../resources/common/common-hook';
import CurrencyInfoModal from '../../../screens/macro-graph/side-wrapper-tabs/input-data-tab/line-accordion-item/info-modals/currency-info';
import DropdownBtn, { Item } from '../dropdownBtn';
import Typography from '../typography';

const monthOptions: Item[] = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' },
  { id: 6, label: '6' },
  { id: 7, label: '7' },
  { id: 8, label: '8' },
  { id: 9, label: '9' },
  { id: 10, label: '10' },
  { id: 11, label: '11' },
  { id: 12, label: '12' },
];

interface Props {
  currencySelected: Item | undefined;
  currencyOptions: Item[];
  setCurrencySelected: (item: Item) => void;
  currencyMonthSelected: Item | undefined;
  setCurrencyMonthSelected: (item: Item | undefined) => void;
  currencyYearSelected: Item | undefined;
  setCurrencyYearSelected: (item: Item | undefined) => void;
  setField?: (type: string, id: string | number) => void;
  disableLocal?: boolean;
  disable?: boolean;
}

export default function CurrencyFilterInput({
  currencyOptions,
  currencySelected,
  setCurrencySelected,
  setField,
  currencyMonthSelected,
  setCurrencyMonthSelected,
  currencyYearSelected,
  setCurrencyYearSelected,
  disableLocal = false,
  disable = false,
}: Props) {
  const { exchangeRateYearOptions, fetchAllExchangeRateYearOptions } = useCommon();
  const [itemsToShow, setItemsToShow] = useState(currencyOptions);
  const [isCurrencyInfoModalOpen, setIsCurrencyInfoModalOpen] = useState(false);

  useEffect(() => {
    if (exchangeRateYearOptions.length === 0) fetchAllExchangeRateYearOptions();
  }, []);

  const showYearOptions = useMemo(() => {
    if (currencySelected?.id === 'DATED') {
      const currentMonth = moment().month() + 1;
      setCurrencyMonthSelected({ id: currentMonth, label: currentMonth.toString() });
      setCurrencyYearSelected({ id: moment().year(), label: moment().year().toString() });

      if (setField) {
        setField('currencyMonth', currentMonth);
        setField('currencyYear', moment().year());
      }
      return exchangeRateYearOptions.filter(year => {
        return Number(year.id) <= moment().year();
      });
    }
    if (currencySelected?.id === 'BDGT DT') {
      setCurrencyYearSelected({ id: moment().year(), label: moment().year().toString() });
      if (setField) {
        setField('currencyYear', moment().year());
      }
      return exchangeRateYearOptions;
    }
    if (setField) {
      setField('currencyYear', 0);
    }
    setCurrencyYearSelected(undefined);
    return [];
  }, [currencySelected]);

  const showMonthsOptions = useMemo(() => {
    if (currencySelected?.id === 'DATED') {
      return monthOptions.filter(month => {
        return Number(month.id) <= moment().month() + 1;
      });
    }
    if (setField) {
      setField('currencyMonth', 0);
    }
    setCurrencyMonthSelected(undefined);
    return [];
  }, [currencySelected]);

  useEffect(() => {
    if (disableLocal === true) {
      const result = currencyOptions.filter(item => item.id !== 'Local');
      setItemsToShow(result);
    } else {
      setItemsToShow(currencyOptions);
    }
  }, [currencyOptions, disableLocal]);

  return (
    <Row>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <Typography fontSize="10px" color="detail-gray">
          Currency
        </Typography>
        <div style={{ cursor: 'pointer' }} onClick={() => setIsCurrencyInfoModalOpen(true)}>
          <InfoRounded />
        </div>
      </div>
      <Col>
        <DropdownBtn
          id="choose-currency-dropdown"
          styleType="filter"
          placeholder="Select"
          items={itemsToShow}
          value={currencySelected}
          disabled={disable}
          onClick={item => {
            if (item) {
              setCurrencySelected(item);
              if (setField) setField('currencyType', item.id);
            }
          }}
        />
      </Col>
      <div style={{ display: 'flex', gap: 24, marginTop: '4px' }}>
        <Col>
          <DropdownBtn
            id="choose-currency-month-dropdown"
            styleType="filter"
            placeholder=""
            items={showMonthsOptions}
            value={currencyMonthSelected}
            onClick={item => {
              if (item) {
                setCurrencyMonthSelected(item);
                if (setField) setField('currencyMonth', item.id);
              }
            }}
            disabled={currencySelected?.id !== 'DATED' || disable}
          />
        </Col>
        <Col>
          <DropdownBtn
            id="choose-currency-day-dropdown"
            styleType="filter"
            placeholder=""
            items={showYearOptions}
            value={currencyYearSelected}
            onClick={item => {
              if (item) {
                setCurrencyYearSelected(item);
                if (setField) setField('currencyYear', item.id);
              }
            }}
            disabled={(currencySelected?.id !== 'DATED' && currencySelected?.id !== 'BDGT DT') || disable}
          />
        </Col>
      </div>

      {/* MODALS */}
      <CurrencyInfoModal isOpen={isCurrencyInfoModalOpen} setIsOpen={setIsCurrencyInfoModalOpen} />
    </Row>
  );
}
